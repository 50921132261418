<template>
  <div
    id="me"
    class="mediaWraper"
    @click="zoomTrack()"
  >
    <p class="user-name">{{localStream.type === 'screen' ? 'Bạn đang trình bày với tất cả mọi người' : 'Bạn'}}</p>
    <div
      class="mediadiv"
      :id="'localMediaDiv' + screen"
      v-show="localStream && localStream.stream"
    >
    </div>
    <div
      class="mediadiv empty-stream"
      v-show="localStream && !localStream.stream"
    >
      <svg
        width="70"
        height="71"
        viewBox="0 0 70 71"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          opacity="0.4"
          d="M46.9583 26.5923C46.9792 26.7381 46.9792 26.9048 46.9583 27.0506C46.9583 26.9048 46.9375 26.759 46.9375 26.6131L46.9583 26.5923Z"
          fill="#484C56"
        />
        <path
          d="M45.9998 24.4881L17.979 52.509C15.0623 50.6548 13.9165 47.3423 13.9165 44.1548V27.4881C13.9165 20.3631 16.6873 17.5923 23.8123 17.5923H36.3123C42.3332 17.5923 45.229 19.5715 45.9998 24.4881Z"
          fill="#484C56"
        />
        <path
          d="M54.5832 15.4673C53.9582 14.8423 52.9373 14.8423 52.3123 15.4673L13.854 53.9256C13.229 54.5506 13.229 55.5715 13.854 56.1965C14.1665 56.4881 14.5832 56.6548 14.979 56.6548C15.3957 56.6548 15.7915 56.4881 16.104 56.1756L54.5832 17.7173C55.2082 17.0923 55.2082 16.0923 54.5832 15.4673Z"
          fill="#484C56"
        />
        <path
          d="M56.625 28.2798V43.3631C56.625 46.3423 55.1667 47.5298 54.3333 47.9673C53.9375 48.1756 53.3125 48.4048 52.5417 48.4048C51.6458 48.4048 50.5417 48.1131 49.25 47.1965L46.1667 45.0298C46.0208 49.634 44.5625 52.259 41.25 53.3631C39.9167 53.8423 38.2708 54.0506 36.2917 54.0506H23.7917C23.2917 54.0506 22.8125 54.0298 22.3125 53.9673L41.25 35.0506L53.0208 23.2798C53.5625 23.3215 54 23.4881 54.3333 23.6756C55.1667 24.1131 56.625 25.3006 56.625 28.2798Z"
          fill="#484C56"
        />
      </svg>

    </div>
  </div>
</template>

<script>
export default {
  name: 'LocalParticipant',
  props: ['localStream', 'screen'],
  mounted () {
    this.appendTrack(this.localStream)
  },
  watch: {
    localStream (localStream) {
      setTimeout(() => {
        this.appendTrack(localStream)
      }, 200)
    }
  },
  methods: {
    appendTrack (localStream) {
      const publicationTrackWraper = document.getElementById('localMediaDiv' + this.screen)
      if (publicationTrackWraper) publicationTrackWraper.innerHTML = ''
      setTimeout(() => {
        if (localStream && localStream.stream) {
          const video = document.createElement('video')
          video.srcObject = localStream.stream
          video.onloadedmetadata = function (e) {
            video.play()
          }
          publicationTrackWraper.appendChild(video)
        }
      }, 100)
    },
    zoomTrack () {
      this.$emit('zoomTrack', this.localStream)
    }
  }
}
</script>

<style scoped>
.empty-stream {
  background-color: #121519;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>