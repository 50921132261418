<template>
  <!-- <div class="table-responsive">
    <table class="table table-vcenter table-mobile-md card-table table-scroll result-table">
      <thead>
        <tr class="row">
          <th class="col-2">
            <span class="ml-sm-2 robo-16-500" style="color: #3153B1;">Xét nghiệm</span>
          </th>
          <th class="col-6 text-center">
            <span class="robo-16-500" style="color: #3153B1;">Kết luận</span>
          </th>
          <th class="col-3">
            <span class="robo-16-500" style="color: #3153B1;">Mô tả</span>
          </th>
          <th class="col-1 text-sm-center">
            <span class="ml-sm-2 robo-16-500" style="color: #3153B1;">Ngày chẩn đoán</span>
          </th>
        </tr>
      </thead>
      <tbody
        v-if="documents && documents.length"
        style="height: 200px; overflow-x: auto; overflow-y: hidden;"
      >
        <tr>
          <td colspan="5">
            <p class="robo-14-500 txt-pri my-1">Hình ảnh</p>
            <div class="w-100 d-flex mb-2">
              <div v-for="(doc,idx) in documents" :key="'rdoc'+doc.id">
                <div v-if="isImage(doc.name)">
                  <img
                    :src="getLinkDownload(doc.link)"
                    alt
                    width="200"
                    height="100"
                    @click="index=idx"
                    class="cursor-pointer mr-2 bg-dark img-fit-cover"
                  />
                  <div class="text-center mt-1 file-name">
                    <p class="txt-pri-corl mb-0">{{doc.content}}</p>
                    <p class="txt-pri-corl">{{formatDateDMY(doc.created_at)}}</p>
                  </div>
                </div>
                <a
                  v-if="!isImage(doc.name)"
                  target="_blank"
                  :href="getLinkDownload(doc.link)"
                  :download="doc.name"
                  class="mr-2"
                  style="width: 200px"
                >
                  <div class="cursor-pointer d-flex justify-content-center align-items-center">
                    <img
                      src="../../../public/assets/images/icon/dowload-file.svg"
                      alt
                      width="100"
                      height="100"
                      class="cursor-pointer"
                    />
                  </div>
                  <div class="text-center mt-1 file-name">
                    <p class="txt-pri-corl mb-0">{{doc.content}}</p>
                    <p class="txt-pri-corl">{{formatDateDMY(doc.created_at)}}</p>
                  </div>
                </a>
              </div>
            </div>
          </td>
        </tr>
      </tbody>
      <tbody v-if="list_results && list_results.count">
        <tr class="my-3 row ml-0" v-for="(test, index) in list_results.data" :key="index">
          <td data-label="Xét nghiệm" class="col-2">
            <span v-html="test.exploration_name" class="reset-border robo-20-400 txt-pri"></span>
          </td>
          <td data-label="Kết luận" class="col-6">
            <span v-html="test.exploration_result" class="reset-border robo-20-400 txt-pri"></span>
          </td>
          <td
            data-label="Mô tả"
            style="
                    min-width: 1000px;
                    width: 100vw;
                    max-width: unset;
                    "
            class="col-3"
          >
            <p v-html="test.exploration_desc" class="robo-20-400 txt-pri mb-0"></p>
          </td>
          <td data-label="Ngày chẩn đoán" class="col-1 text-sm-center">
            <span class="robo-20-400 txt-pri">{{formatDateDMY(test.exploration_date) || ''}}</span>
          </td>
        </tr>
      </tbody>
      <tbody v-if="!list_results || !list_results.count">
        <tr>
          <td colspan="5">
            <div class="text-center pt-3 robo-20-500 txt-pri">Chưa có kết quả</div>
          </td>
        </tr>
      </tbody>
    </table>
   
  </div>-->
  <div class="table-responsive">
    <div
      style="min-width:2000px"
      class="table table-vcenter table-mobile-md card-table table-scroll result-table"
      v-for="(test, index) in list_results.data"
      :key="index"
    >
      <div>
        <div>Xét nghiệm</div>
        <div v-html="test.exploration_name" class="robo-20-400 txt-pri"></div>
      </div>
      <div>
        <div>Kết luận</div>
        <div v-html="test.exploration_result" class="robo-20-400 txt-pri"></div>
      </div>
      <div>
        <div>Mô tả</div>
        <div v-html="test.exploration_desc" class="robo-20-400 txt-pri"></div>
      </div>
      <div>
        <div>Ngày chẩn đoán</div>
        <span class="robo-20-400 txt-pri">{{formatDateDMY(test.exploration_date) || ''}}</span>
      </div>
    </div>
    <Pagination
      v-if="list_results && list_results.count && list_results.count < list_results.total"
      :items="list_results"
      :current_page="current_page"
      @onRefresh="getResultsByDiseases"
    ></Pagination>
  </div>
</template>

<script>
import Pagination from '../Pagination.vue'
import appUtils from '../../utils/appUtils'

export default {
  name: 'ResultTable',
  components: { Pagination },
  props: ['list_results', 'documents'],
  methods: {
    getResultsByDiseases () {
      this.$emit('getResultsByDiseases')
    },
    getLinkDownload (link) {
      return appUtils.getDocumentURL(link)
    },
    formatDateDMY (date) {
      return window.moment(date).format('DD/MM/YYYY')
    },
    isImage (file_name) {
      return file_name.toLowerCase().match(/\.(jpeg|jpg|gif|png)$/) != null
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep {
  .reset-border * {
    border: none !important;
    color: #20419b !important;
  }
}
</style>