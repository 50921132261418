<template>
  <div
    :id="participant.participant.identity"
    class="mediaWraper"
    v-if="participant"
    @click="zoomTrack()"
  >
    <!-- <p class="user-name">
      {{ getNameMember }}
    </p>-->

    <div class="meet-mic-status" v-show="has_audio">
      <svg
        width="16"
        height="17"
        viewBox="0 0 16 17"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M12.7466 6.31135C12.4866 6.31135 12.28 6.51802 12.28 6.77802V7.83135C12.28 10.1914 10.36 12.1114 7.99995 12.1114C5.63995 12.1114 3.71995 10.1914 3.71995 7.83135V6.77135C3.71995 6.51135 3.51329 6.30469 3.25329 6.30469C2.99329 6.30469 2.78662 6.51135 2.78662 6.77135V7.82469C2.78662 10.538 4.87329 12.7714 7.53329 13.0114V14.4314C7.53329 14.6914 7.73995 14.898 7.99995 14.898C8.25995 14.898 8.46662 14.6914 8.46662 14.4314V13.0114C11.12 12.778 13.2133 10.538 13.2133 7.82469V6.77135C13.2066 6.51802 13 6.31135 12.7466 6.31135Z"
          fill="white"
        />
        <path
          d="M8.00001 1.56445C6.37334 1.56445 5.05334 2.88445 5.05334 4.51112V7.92445C5.05334 9.55112 6.37334 10.8711 8.00001 10.8711C9.62668 10.8711 10.9467 9.55112 10.9467 7.92445V4.51112C10.9467 2.88445 9.62668 1.56445 8.00001 1.56445ZM8.87335 6.19779C8.82668 6.37112 8.67335 6.48445 8.50001 6.48445C8.46668 6.48445 8.43335 6.47779 8.40001 6.47112C8.14001 6.39779 7.86668 6.39779 7.60668 6.47112C7.39334 6.53112 7.18668 6.40445 7.13334 6.19779C7.07334 5.99112 7.20001 5.77779 7.40668 5.72445C7.80001 5.61779 8.21335 5.61779 8.60668 5.72445C8.80668 5.77779 8.92668 5.99112 8.87335 6.19779ZM9.22668 4.90445C9.16668 5.06445 9.02001 5.15779 8.86001 5.15779C8.81335 5.15779 8.77335 5.15112 8.72668 5.13779C8.26001 4.96445 7.74001 4.96445 7.27334 5.13779C7.07334 5.21112 6.84668 5.10445 6.77334 4.90445C6.70001 4.70445 6.80668 4.47779 7.00668 4.41112C7.64668 4.17779 8.35334 4.17779 8.99335 4.41112C9.19335 4.48445 9.30001 4.70445 9.22668 4.90445Z"
          fill="white"
        />
      </svg>
    </div>
    <div class="meet-mic-status" v-show="!has_audio">
      <svg
        width="16"
        height="17"
        viewBox="0 0 16 17"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M10.9467 4.74363V5.51697L6.09335 10.3703C5.45335 9.7903 5.05334 8.93697 5.05334 8.02363V4.74363C5.05334 3.3703 5.98668 2.2303 7.25335 1.90363C7.38001 1.8703 7.50001 1.97697 7.50001 2.10363V3.1303C7.50001 3.40363 7.72668 3.6303 8.00001 3.6303C8.27334 3.6303 8.50001 3.40363 8.50001 3.1303V2.10363C8.50001 1.97697 8.62001 1.8703 8.74668 1.90363C10.0133 2.2303 10.9467 3.3703 10.9467 4.74363Z"
          fill="#696F7F"
        />
        <path
          d="M13.2067 7.00395V8.06395C13.2067 10.7773 11.12 13.0106 8.46671 13.2439V14.6639C8.46671 14.9239 8.26005 15.1306 8.00005 15.1306C7.74005 15.1306 7.53338 14.9239 7.53338 14.6639V13.2439C6.80671 13.1773 6.12005 12.9639 5.50671 12.6239L6.19338 11.9373C6.74005 12.1906 7.35338 12.3373 8.00005 12.3373C10.36 12.3373 12.28 10.4173 12.28 8.06395V7.00395C12.28 6.75061 12.4867 6.54395 12.7467 6.54395C13 6.54395 13.2067 6.75061 13.2067 7.00395Z"
          fill="#696F7F"
        />
        <path
          d="M10.9467 7.18359V8.15026C10.9467 9.87026 9.4667 11.2503 7.7067 11.0836C7.52003 11.0636 7.33337 11.0303 7.16003 10.9703L10.9467 7.18359Z"
          fill="#696F7F"
        />
        <path
          d="M14.5133 1.95078C14.3133 1.75078 13.9866 1.75078 13.7866 1.95078L4.81996 10.9174C4.13329 10.1641 3.71996 9.16411 3.71996 8.06411V7.00412C3.71996 6.75078 3.51329 6.54411 3.25329 6.54411C2.99996 6.54411 2.79329 6.75078 2.79329 7.00412V8.06411C2.79329 9.41745 3.31329 10.6508 4.15996 11.5774L1.47996 14.2574C1.27996 14.4574 1.27996 14.7841 1.47996 14.9841C1.58662 15.0774 1.71329 15.1308 1.84662 15.1308C1.97996 15.1308 2.10662 15.0774 2.20662 14.9774L14.5133 2.67078C14.72 2.47078 14.72 2.15078 14.5133 1.95078Z"
          fill="#696F7F"
        />
      </svg>
    </div>
    <div
      class="mediadiv"
      ref="mediaDiv"
      :class="isFullScreen?'showFullScreen':''"
      v-show="has_video"
    >
      <!-- <div v-if="isFullScreen" @click="handleShowFullScreen(false)" class="icon-fullscreen">
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M16 9H21C21.2652 9 21.5196 8.89464 21.7071 8.70711C21.8946 8.51957 22 8.26522 22 8C22 7.73478 21.8946 7.48043 21.7071 7.29289C21.5196 7.10536 21.2652 7 21 7H17V3C17 2.73478 16.8946 2.48043 16.7071 2.29289C16.5196 2.10536 16.2652 2 16 2C15.7348 2 15.4804 2.10536 15.2929 2.29289C15.1054 2.48043 15 2.73478 15 3V8C15 8.26522 15.1054 8.51957 15.2929 8.70711C15.4804 8.89464 15.7348 9 16 9ZM8 15H3C2.73478 15 2.48043 15.1054 2.29289 15.2929C2.10536 15.4804 2 15.7348 2 16C2 16.2652 2.10536 16.5196 2.29289 16.7071C2.48043 16.8946 2.73478 17 3 17H7V21C7 21.2652 7.10536 21.5196 7.29289 21.7071C7.48043 21.8946 7.73478 22 8 22C8.26522 22 8.51957 21.8946 8.70711 21.7071C8.89464 21.5196 9 21.2652 9 21V16C9 15.7348 8.89464 15.4804 8.70711 15.2929C8.51957 15.1054 8.26522 15 8 15ZM8 2C7.73478 2 7.48043 2.10536 7.29289 2.29289C7.10536 2.48043 7 2.73478 7 3V7H3C2.73478 7 2.48043 7.10536 2.29289 7.29289C2.10536 7.48043 2 7.73478 2 8C2 8.26522 2.10536 8.51957 2.29289 8.70711C2.48043 8.89464 2.73478 9 3 9H8C8.26522 9 8.51957 8.89464 8.70711 8.70711C8.89464 8.51957 9 8.26522 9 8V3C9 2.73478 8.89464 2.48043 8.70711 2.29289C8.51957 2.10536 8.26522 2 8 2ZM21 15H16C15.7348 15 15.4804 15.1054 15.2929 15.2929C15.1054 15.4804 15 15.7348 15 16V21C15 21.2652 15.1054 21.5196 15.2929 21.7071C15.4804 21.8946 15.7348 22 16 22C16.2652 22 16.5196 21.8946 16.7071 21.7071C16.8946 21.5196 17 21.2652 17 21V17H21C21.2652 17 21.5196 16.8946 21.7071 16.7071C21.8946 16.5196 22 16.2652 22 16C22 15.7348 21.8946 15.4804 21.7071 15.2929C21.5196 15.1054 21.2652 15 21 15Z"
            fill="white"
          />
        </svg>
      </div>
      <div v-else @click="handleShowFullScreen(true)" class="icon-fullscreen">
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M8 2H3C2.73478 2 2.48043 2.10536 2.29289 2.29289C2.10536 2.48043 2 2.73478 2 3V8C2 8.26522 2.10536 8.51957 2.29289 8.70711C2.48043 8.89464 2.73478 9 3 9C3.26522 9 3.51957 8.89464 3.70711 8.70711C3.89464 8.51957 4 8.26522 4 8V4H8C8.26522 4 8.51957 3.89464 8.70711 3.70711C8.89464 3.51957 9 3.26522 9 3C9 2.73478 8.89464 2.48043 8.70711 2.29289C8.51957 2.10536 8.26522 2 8 2ZM8 20H4V16C4 15.7348 3.89464 15.4804 3.70711 15.2929C3.51957 15.1054 3.26522 15 3 15C2.73478 15 2.48043 15.1054 2.29289 15.2929C2.10536 15.4804 2 15.7348 2 16V21C2 21.2652 2.10536 21.5196 2.29289 21.7071C2.48043 21.8946 2.73478 22 3 22H8C8.26522 22 8.51957 21.8946 8.70711 21.7071C8.89464 21.5196 9 21.2652 9 21C9 20.7348 8.89464 20.4804 8.70711 20.2929C8.51957 20.1054 8.26522 20 8 20ZM21 2H16C15.7348 2 15.4804 2.10536 15.2929 2.29289C15.1054 2.48043 15 2.73478 15 3C15 3.26522 15.1054 3.51957 15.2929 3.70711C15.4804 3.89464 15.7348 4 16 4H20V8C20 8.26522 20.1054 8.51957 20.2929 8.70711C20.4804 8.89464 20.7348 9 21 9C21.2652 9 21.5196 8.89464 21.7071 8.70711C21.8946 8.51957 22 8.26522 22 8V3C22 2.73478 21.8946 2.48043 21.7071 2.29289C21.5196 2.10536 21.2652 2 21 2ZM21 15C20.7348 15 20.4804 15.1054 20.2929 15.2929C20.1054 15.4804 20 15.7348 20 16V20H16C15.7348 20 15.4804 20.1054 15.2929 20.2929C15.1054 20.4804 15 20.7348 15 21C15 21.2652 15.1054 21.5196 15.2929 21.7071C15.4804 21.8946 15.7348 22 16 22H21C21.2652 22 21.5196 21.8946 21.7071 21.7071C21.8946 21.5196 22 21.2652 22 21V16C22 15.7348 21.8946 15.4804 21.7071 15.2929C21.5196 15.1054 21.2652 15 21 15Z"
            fill="white"
          />
        </svg>
      </div>-->
    </div>

    <div class="mediadiv empty-stream" v-show="!has_video">
      <svg
        width="70"
        height="71"
        viewBox="0 0 70 71"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          opacity="0.4"
          d="M46.9583 26.5923C46.9792 26.7381 46.9792 26.9048 46.9583 27.0506C46.9583 26.9048 46.9375 26.759 46.9375 26.6131L46.9583 26.5923Z"
          fill="#484C56"
        />
        <path
          d="M45.9998 24.4881L17.979 52.509C15.0623 50.6548 13.9165 47.3423 13.9165 44.1548V27.4881C13.9165 20.3631 16.6873 17.5923 23.8123 17.5923H36.3123C42.3332 17.5923 45.229 19.5715 45.9998 24.4881Z"
          fill="#484C56"
        />
        <path
          d="M54.5832 15.4673C53.9582 14.8423 52.9373 14.8423 52.3123 15.4673L13.854 53.9256C13.229 54.5506 13.229 55.5715 13.854 56.1965C14.1665 56.4881 14.5832 56.6548 14.979 56.6548C15.3957 56.6548 15.7915 56.4881 16.104 56.1756L54.5832 17.7173C55.2082 17.0923 55.2082 16.0923 54.5832 15.4673Z"
          fill="#484C56"
        />
        <path
          d="M56.625 28.2798V43.3631C56.625 46.3423 55.1667 47.5298 54.3333 47.9673C53.9375 48.1756 53.3125 48.4048 52.5417 48.4048C51.6458 48.4048 50.5417 48.1131 49.25 47.1965L46.1667 45.0298C46.0208 49.634 44.5625 52.259 41.25 53.3631C39.9167 53.8423 38.2708 54.0506 36.2917 54.0506H23.7917C23.2917 54.0506 22.8125 54.0298 22.3125 53.9673L41.25 35.0506L53.0208 23.2798C53.5625 23.3215 54 23.4881 54.3333 23.6756C55.1667 24.1131 56.625 25.3006 56.625 28.2798Z"
          fill="#484C56"
        />
      </svg>
    </div>
  </div>
</template>

<script>
import PublicationTrack from './PublicationTrack.vue'
export default {
  name: 'Participant',
  props: ['participant', 'userMembers', 'tracks'],
  components: { PublicationTrack },
  data () {
    return {
      is_screen: false,
      has_video: false,
      has_audio: false,
      isFullScreen: false
    }
  },
  mounted () {
    this.appendTracks()
  },
  watch: {
    participant (p) {
      this.appendTracks()
    },
    tracks (p) {
      const publicationTrackWraper = this.$refs.mediaDiv
      if (publicationTrackWraper) publicationTrackWraper.innerHTML = ''
      this.appendTracks()
    }
  },
  computed: {
    getNameMember () {
      console.log(this.userMembers)
      let id = this.participant?.participant?.identity
      if (!this.userMembers) return id
      let found = this.userMembers.find(m => m.id === parseInt(id))
      console.log(found)
      if (this.is_screen) {
        return (found ? found.name : id) + ' đang trình bày'
      } else {
        return found ? found.name : id
      }
    }
  },
  methods: {
    handleClose () {
      this.isFullScreen = false
    },
    handleShowFullScreen (isFull) {
      this.isFullScreen = isFull
    },
    isScreen (name) {
      return name?.includes('screen')
    },
    zoomTrack () {
      this.$emit('zoomTrack', this.participant)
    },
    appendTracks () {
      const self = this
      const tracks = this.tracks
      let hasScreen = false
      let hasVideo = false
      let hasAudio = false
      if (tracks) {
        tracks.forEach(t => {
          if (self.isScreen(t.name)) {
            hasScreen = true
          }
          if (t.kind === 'video') {
            hasVideo = true
          }
          if (t.kind === 'audio') {
            hasAudio = true
          }
          this.appendTrack(t)
        })
      }
      this.has_video = hasVideo
      this.has_audio = hasAudio
      this.is_screen = hasScreen
    },
    appendTrack (t) {
      const publicationTrackWraper = this.$refs.mediaDiv
      if (publicationTrackWraper) {
        if (t) {
          publicationTrackWraper.appendChild(t.attach())
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.empty-stream {
  background-color: #121519;
  display: flex;
  justify-content: center;
  align-items: center;
}
.mediaWraper {
  width: 100%;
  height: 100%;
}

::v-deep {
  .mediadiv {
    video {
      object-fit: cover;
    }
  }
}

.mediadiv {
  position: relative;

  &:hover {
    .icon-fullscreen {
      display: block;
    }
  }
}

.icon-fullscreen {
  display: none;
  position: absolute;
  bottom: 10px;
  right: 5px;
  z-index: 3000;
}

.showFullScreen {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 2000;
  border-radius: unset !important;
}
</style>