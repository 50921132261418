<template>
    <div>
        <div class="modal" tabindex="-1" role="dialog" id="modal__topic">
            <div class="modal-dialog modal-xl" role="document">
                <div class="modal-content">
                    <div class="modal-header bg-white border-0">
                        <h5 class="modal-title" style="color: #20419B;">Chỉnh sửa</h5>
                        <small class="mr-3" data-dismiss="modal" aria-label="Close" style="cursor: pointer; color: #4F4F4F;">&times;</small>
                    </div>
                    <div class="modal-body">
                    	<div class="row mt-2">
                    		<div class="form-group">
                    			<div class="bg-white border p-1" >
                    				<textarea class="form-control robo-20-400 w-100 bg-white" :class="(!!topic_images && topic_images.length) || (!!topic_videos && topic_videos.length) || (!!topic_files && topic_files.length) || (list_medias && list_medias.length) ? 'border-bottom bd-muted' : ''" rows="3" placeholder="Nhập nội dung" v-model="txt_content" style="height: 200px;"></textarea>
                    				<div class="p-2 d-flex flex-wrap">
                    					<TopicFileView v-if="!!list_medias && list_medias.length" :medias="list_medias" :is_edit="true" @deleteMedia="deleteMedia"></TopicFileView>
                              <TopicFilePreview :topic_images="topic_images" :topic_videos="topic_videos" :topic_files="topic_files" @deleteMedia="deleteMediaLocal"></TopicFilePreview>
                    				</div>
                    			</div>
                    			<span class="robo-12-400 text--red" v-if="!txt_content && content_error">Vui lòng nhập nội dung!</span>
                    		</div>
                    		<div class="d-flex justify-content-between mt-2 mb-2">
                          <TopicPicker @changeImage="changeImage" @changeVideo="changeVideo" @changeFile="changeFile"></TopicPicker>
                    			<div>
                    				<button class="btn bg-pri bd-pri text-white mb-1" style="width: 120px; height: 44px;" @click="onSubmit()">
                    					Gửi
                    				</button>
                    			</div>
                    		</div>
                    	</div>
                    </div>
                </div>
            </div>
        </div>
        <CoolLightBox v-if="" :items="getImagesForCoolightLocal(list_medias_local)" :index="index_media_local" :useZoomBar="true" @close="index_media_local = null"></CoolLightBox>
    </div>
</template>
<script>
import appUtils from '../../utils/appUtils.js'
import TopicFileView from '../FilePicker/TopicFileView.vue'
import TopicFilePreview from '../FilePicker/TopicFilePreview.vue'
import TopicPicker from '../FilePicker/TopicPicker.vue'
export default {
  name: 'ModalTopicEdit',
  props: ['item_edit', 'is_topic'],
  components: {TopicFileView, TopicFilePreview, TopicPicker},
  data () {
    return {
      txt_content: null,
      content_error: null,
      topic_images: [],
      topic_videos: null,
      topic_files: null,
      list_medias_local: null,
      index_media_local: null,
      list_medias: null,
      list_medias_delete: [],
      topic: false,
      item_id: ''
    }
  },
  watch: {
    item_edit: function (_new, old) {
      let self = this
      if (_new) {
        if (_new.content) {
          self.txt_content = _new.content
        }
        if (_new.medias) {
          self.list_medias = _new.medias
        }
        self.item_id = _new.id
      } else {
        self.txt_content = null
        self.list_medias = null
        self.list_medias_delete = []
      }
    },
    is_topic: function (_new, old) {
      let self = this
      self.topic = _new
    }
  },
  mounted () {
  },
  methods: {
    changeImage (_files) {
      let self = this
      self.topic_images = _files
    },
    changeVideo (_files) {
      let self = this
      self.topic_videos = _files
    },
    changeFile (_files) {
      let self = this
      self.topic_files = _files
    },
    getBase64URL (file) {
      return URL.createObjectURL(file)
    },
    getImageURL (path) {
      return appUtils.getImageURL(path)
    },
    onShowMediaLocal (medias, index) {
      let self = this
      self.list_medias_local = medias
      self.index_media_local = index
    },
    getImagesForCoolightLocal (files) {
      let results = []
      if (files) {
        files.forEach(file => {
          results.push({
            title: this.txt_content || 'File đã chọn',
            src: file.path ? this.getImageURL(file.path) : this.getBase64URL(file),
            type: file.type
          })
        })
      }
      return results
    },
    async onSubmit () {
      let self = this
      try {
        if (!self.txt_content) {
          self.content_error = 'Vui lòng nhập nội dung'
        } else {
          self.content_error = ''
          let params = new FormData()
          params.append('content', self.txt_content)
          if (!!self.topic_images && self.topic_images.length) {
            self.topic_images.forEach(item => {
              if (item) {
                if (item.name.toLowerCase().match(/\.(jpeg|jpg|gif|png)$/) != null) {
                  params.append('images[]', item)
                }
              }
            })
          }
          if (!!self.topic_videos && self.topic_videos.length) {
            self.topic_videos.forEach(item => {
              if (item) {
                if (item.name.toLowerCase().match(/\.(mp4|ogx|oga|ogv|ogg|webm)$/) != null) {
                  params.append('videos[]', item)
                }
              }
            })
          }
          if (!!self.topic_files && self.topic_files.length) {
            self.topic_files.forEach(item => {
              if (item) {
                if (item.name.toLowerCase().match(/\.(pdf|docx|doc|xlsx|xls)$/) != null) {
                  params.append('files[]', item)
                }
              }
            })
          }
          if (self.topic) {
            await self.$rf.getRequest('DoctorRequest').editTopic(self.$route.params.topic_id, params).then((resp) => {
              if (self.list_medias_delete.length > 0) {
                self.deleteMedias()
              } else {
                self.$emit('onRefresh', self.topic)
              }
              self.topic_images = []
            })
          } else {
            await self.$rf.getRequest('DoctorRequest').editComment(self.item_id, params).then((resp) => {
              if (self.list_medias_delete.length > 0) {
                self.deleteMedias()
              } else {
                self.$emit('onRefresh', self.topic)
              }
              self.topic_images = []
              self.topic_videos = []
              self.topic_files = []
            })
          }
        }
      } catch (e) {
        // statements
        console.log(e)
      }
    },
    async deleteMedias () {
      let self = this
      try {
        var params = {
          ids: self.list_medias_delete
        }
        await self.$rf.getRequest('DoctorRequest').deleteMedias(params).then((resp) => {
          self.$emit('onRefresh', self.topic)
        })
      } catch (e) {
        console.log(e)
      }
    },
    deleteMediaLocal (idx, type) {
      let self = this
      if (type === 1) self.topic_images = self.topic_images.filter((ele, index) => index !== idx)
      if (type === 2) self.topic_videos = self.topic_videos.filter((ele, index) => index !== idx)
      if (type === 3) self.topic_files = self.topic_files.filter((ele, index) => index !== idx)
    },
    deleteMedia (idx, item) {
      let self = this
      self.list_medias = self.list_medias.filter((ele, index) => index !== idx)
      if (item) {
        self.list_medias_delete.push(item.id)
      }
    }
  }
}
</script>
<style scoped="true">
</style>