<template>
    <div class="table-responsive">
        <table class="table table-vcenter table-mobile-md card-table table-scroll">
            <thead>
                <tr>
                    <th><span class="ml-sm-3 robo-16-500" style="color: #3153B1;">Loại cuộc gọi</span></th>
                    <th><span class="robo-16-500" style="color: #3153B1;">Trạng thái</span></th>
                    <th><span class="robo-16-500" style="color: #3153B1;">Thời gian gọi</span></th>
                    <th><span class="robo-16-500" style="color: #3153B1;">Ngày gọi</span></th>
                </tr>
            </thead>
            <tbody v-if="logs && logs.count">
                <tr class="mt-2 mb-2" v-for="(log, index) in logs.data" :key="index">
                    <td data-label="Loại cuộc gọi" class="pl-sm-3">
                        <div v-if="!log.is_videocall" class="d-flex align-items-center">
                            <img src="../../../public/assets/images/icon/voice-call-icon-calllog.svg" alt="" width="24" height="24">
                            <p class="mb-0 robo-16-400 txt-black ml-2">
                                Thoại
                            </p>
                        </div>
                        <div v-if="log.is_videocall" class="d-flex align-items-center">
                            <img src="../../../public/assets/images/icon/video-icon-calllog.svg" alt="" width="24" height="24">
                            <p class="mb-0 robo-16-400 txt-black ml-2">
                                Video
                            </p>
                        </div>
                    </td>
                    <td data-label="Trạng thái" :set="status = getCallLogStatus(log)">
                        <div v-if="status == 1" class="d-flex align-items-center">
                            <img src="../../../public/assets/images/icon/goi-di.svg" alt="" width="24" height="24">
                            <p class="mb-0 robo-16-400 txt-black ml-2">
                                Gọi đi
                            </p>
                        </div>
                        <div v-if="status == 2" class="d-flex align-items-center">
                            <img src="../../../public/assets/images/icon/goi-den.svg" alt="" width="24" height="24">
                            <p class="mb-0 robo-16-400 txt-black ml-2">
                                Gọi đến
                            </p>
                        </div>
                        <div v-if="status == 3" class="d-flex align-items-center">
                            <img src="../../../public/assets/images/icon/goi-nho.svg" alt="" width="24" height="24">
                            <p class="mb-0 robo-16-400 txt-black ml-2">
                                Gọi nhỡ
                            </p>
                        </div>
                    </td>
                    <td data-label="Thời gian gọi" class="pl-sm-2">
                        {{getTimeFromSecond(log.answer_duration)}}
                    </td>
                    <td data-label="Ngày gọi" class="robo-16-500">
                        {{formatTimeDMY(log.start_time)}}
                    </td>
                </tr>
            </tbody>
            <tbody v-if="(!logs || !logs.count)">
                <tr>
                    <td colspan="5">
                        <div class="text-center pt-3 robo-16-500 txt-pri">
                            Chưa có cuộc gọi nào
                        </div>
                    </td>
                </tr>
            </tbody>
        </table>
        <Pagination v-if="logs && logs.count" :items="logs" :current_page="current_page" @onRefresh="getCallLogs"></Pagination>
    </div>
</template>
<script>
import Pagination from '../../components/Pagination.vue'
import appUtils from '../../utils/appUtils'
const log_status = {
  call_away: 1,
  incoming_call: 2,
  missing: 3
}
export default {

  name: 'CallLogsTable',
  props: ['logs', 'current_page'],
  components: { Pagination },
  data () {
    return {
      user: appUtils.getLocalUser(),
      log_status
    }
  },
  methods: {
    getCallLogs () {
      this.$emit('getCallLogs')
    },
    getCallLogStatus (log) {
      if (!log) return
      if (log.from?.id === this.user?.id) {
        // if(parseInt(log.answer_time)) {
        //     return this.log_status?.call_away
        // }else {
        //     return this.log_status?.incoming_call
        // }
        return this.log_status?.call_away
      }
      if (log.to?.id === this.user?.id) {
        if (parseInt(log.answer_time)) {
          return this.log_status?.incoming_call
        } else {
          return this.log_status?.missing
        }
      }
    },
    getTimeFromSecond (sc) {
      return window.moment.utc(sc * 1000).format('HH:mm:ss')
    },
    formatTimeDMY (date_time) {
      return window.moment(date_time).format('HH:mm - DD/MM/YYYY')
    }
  }
}
</script>
<style lang="css" scoped>
</style>