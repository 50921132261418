<template>
  <div>
    <div
      class="modal fade"
      :id="modal_id || 'modalUploadFile'"
      tabindex="-1"
      aria-labelledby="modalUploadFileTitle"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-sm modal-dialog-centered">
        <div class="modal-content shadow1 border">
          <div class="modal-header border-0 bg-white mt-3 mb-0">
            <h5 class="ml-4 fs-18 fw-700 txt-black">Chọn tệp</h5>
            <img
              src="../../../public/assets/images/icon/close-gray.svg"
              height="25px"
              class="cursor-pointer mr-3 mb-2"
              data-dismiss="modal"
              aria-label="Close"
            />
          </div>
          <div class="modal-body">
            <div class="upload-btn mb-3" @click="uploadDocs">
              <img
                class="mr-1"
                src="../../../public/assets/images/icon/u_upload.svg"
              />
              <span class="fs-14 txt-pri">Chọn file</span>
            </div>
            <div
              class="mb-3"
              v-for="(doc, i) in nameFiles"
              :key="i + 'slt_file'"
            >
              <div
                class="d-flex w-100 justify-content-between align-items-center"
              >
                <el-input
                  v-model="doc.description"
                  class="fs-14 fw-600 mb-0 max-line-1 w-265px"
                />
                <svg
                  width="24"
                  height="25"
                  viewBox="0 0 24 25"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  class="cursor-pointer"
                  @click="deleteSltDoct(i)"
                >
                  <path
                    d="M6.00012 18.4998L17.9999 6.5"
                    stroke="#292D32"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M17.9999 18.4998L6.00012 6.5"
                    stroke="#292D32"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
            </div>
          </div>
          <div class="modal-footer border-0 justify-content-center mb-2">
            <button
              type="button"
              class="btn bg-pri bd-pri text-white upload-btn2 radius-10 w-100"
              @click="onUpload"
              :disabled="!selected_docs || !selected_docs.length"
            >
              {{btnText || 'Xong'}}
            </button>
          </div>
        </div>
      </div>
    </div>
    <input
      type="file"
      @change="onSelectedFile($event)"
      class="d-none"
      ref="inputFile"
      multiple
    />
  </div>
</template>

<script>
export default {
  name: 'UploadFiles',
  props: ['modal_id', 'btnText', 'selected'],
  data () {
    return {
      selected_docs: [],
      nameFiles: []
    }
  },
  mounted () {
    let self = this
    this.$nextTick(function () {
      window.$('#' + this.modal_id || 'modalUploadFile').on('shown.bs.modal', function (e) {
        if (self.selected) {
          self.selected_docs = self.selected?.files
          self.nameFiles = self.selected?.nameFiles
        }
      })
    })
  },
  methods: {
    onUpload () {
      if (!this.selected_docs || !this.selected_docs.length) {
        alert('Vui lòng chọn tài liệu')
        return
      }
      this.$emit('submitFile', {files: this.selected_docs, nameFiles: this.nameFiles})
      this.selected_docs = []
      this.nameFiles = []
      this.modalUploadFile(false)
    },
    uploadDocs () {
      this.$refs.inputFile.click()
      this.$refs.inputFile.value = null
    },
    modalUploadFile (show) {
      window.$('#' + this.modal_id || 'modalUploadFile').modal(show ? 'show' : 'hide')
    },
    deleteSltDoct (i) {
      this.selected_docs.splice(i, 1)
      this.nameFiles.splice(i, 1)
    },
    onSelectedFile (e) {
      const files = e.target.files
      if (files) {
        for (let i = 0; i < files.length; i++) {
          this.nameFiles.push({
            description: files[i].name
          })
          this.selected_docs.push(files[i])
        }
        this.$refs.inputFile.value = null
      }
    }
  }

}
</script>

<style scoped>
.shadow1 {
    
  box-shadow: 0 8px 8px 0 rgba(196, 196, 196, 0.11),
    0 12px 18px 0 rgba(184, 184, 184, 0.11);
}
.border {
    border: solid 1px rgba(196, 196, 196) !important;
}
  .upload-btn {
    width: 100%;
    border: 1px dashed #20419b;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 70px;
    border-radius: 9px;
    cursor: pointer;
  }
  .upload-btn2 {
    padding: 18px 0px 18px 0px;
  }
  .w-265px {
    width: 100%;
    max-width: 265px;
  }
</style>