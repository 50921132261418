<template>
  <div>
    <div
      class="modal fade"
      :id="modal_id || 'modalUploadLink'"
      tabindex="-1"
      aria-labelledby="modalUploadLinkTitle"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-sm modal-dialog-centered">
        <div class="modal-content shadow1 border">
          <div class="d-flex justify-content-between p-3 pb-0">
            <div class="fs-24 fw-700 txt-grey-900 text-center w-100">
              Thêm Link
            </div>
            <button
              type="button"
              class="close bg-white border-0"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true" class="fs-30 fw-500">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="flex justify-between items-center mb-2">
              <div class="w-20">Đường dẫn</div>
              <el-input v-model="formLink.url"></el-input>
            </div>
            <div class="flex justify-between items-center mb-2">
              <div class="w-20">Tên Link</div>
              <el-input v-model="formLink.name"></el-input>
            </div>
            <div class="mt-3">
              <button type="button" class="btn text-white w-100 bg-pri" @click="submitLink">{{btnText || 'Xong'}}</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'UploadLink',
  props: ['modal_id', 'btnText'],
  data () {
    return {
      formLink: {
        name: '',
        url: ''
      }
    }
  },
  methods: {
    submitLink () {
      if (!this.formLink.name || !this.formLink.url) {
        alert('Vui lòng điền đủ thông tin')
        return
      }
      this.$emit('addLink', this.formLink)
      this.modalUploadLink(false)
      this.formLink = {
        name: '',
        url: ''
      }
    },
    modalUploadLink (show) {
      window.$('#' + this.modal_id || 'modalUploadLink').modal(show ? 'show' : 'hide')
    }
  }
}
</script>

<style scoped>
.shadow1 {
    
  box-shadow: 0 8px 8px 0 rgba(196, 196, 196, 0.11),
    0 12px 18px 0 rgba(184, 184, 184, 0.11);
}
.border {
    border: solid 1px rgba(196, 196, 196) !important;
}
</style>