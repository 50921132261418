<template>
  <div class="p-3 mb-3">
    <!-- Page title -->
    <div class="card-header">
      <h2 class="title">
        Thiết lập kích thước lề văn bản
      </h2>
    </div>

    <div class="card-body">
      <form @submit.prevent="onSubmit" autocomplete="off">

        <div class="row">
          <div class="col-6">
            <div class="form-group mb-3">
              <label class="required form-label">Lề Trên:</label>
              <input type="number" class="form-control" v-model="form.padding.top">
            </div>
          </div>

          <div class="col-6">
            <div class="form-group mb-3">
              <label class="required form-label">Lề Dưới:</label>
              <input type="number" class="form-control" v-model="form.padding.bottom">
            </div>
          </div>
        </div>


        <div class="row">
          <div class="col-6">
            <div class="form-group mb-3">
              <label class="required form-label">Lề Trái:</label>
              <input type="number" class="form-control" v-model="form.padding.left">
            </div>
          </div>

          <div class="col-6">
            <div class="form-group mb-3">
              <label class="required form-label">Lề Phải:</label>
              <input type="number" class="form-control" v-model="form.padding.left">
            </div>
          </div>
        </div>

        <div class="form-group mb-3 text-center">
          <button type="submit" class="btn bg-pri bd-pri text-white">Cập Nhật</button>
        </div>

      </form>
    </div>
  </div>
</template>

<script>
  import {mapState} from 'vuex'

  export default {
    name: 'TemplatePadding',
    props: ['state_name'],
    computed: {
      ...mapState({
        form: state => state[this.state_name].form
      })
    },
    methods: {
      onSubmit () {
        this.$emit('onSubmit', this.form)
      }
    }
  }
</script>
