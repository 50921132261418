<template>
  <div
      class="modal fade bd-example-modal-lg"
      id="VerifyCalendarKyc"
      tabindex="-1"
      role="dialog"
      aria-labelledby="myLargeModalLabel"
      aria-hidden="true"
  >
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="d-flex justify-content-end bg-white border-20px">
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"
            data-dismiss="modal"
            aria-label="Close"
            class="mt-3 mr-3 cursor-pointer"
            >
            <path d="M6 17.9998L17.9998 6" stroke="#292D32" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M17.9998 17.9998L6 6" stroke="#292D32" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
        </div>

        <div class="modal-body">
          <form>
            <div class="row container px-4">
              <div class="col-md-4 d-flex align-items-center">
                <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect width="48" height="48" rx="24" fill="#45B36B"/>
                <path d="M18 24L22 28L30 20" stroke="#FCFCFD" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
                <p class="mt-3 ml-2">{{$t('KYC_steps.btn_submit')}}</p>
              </div>
              <div class="col-md-4">
                <svg
                    class="my-4"
                    width="120"
                    height="24"
                    viewBox="0 0 96 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                      d="M2 10.5C1.17157 10.5 0.5 11.1716 0.5 12C0.5 12.8284 1.17157 13.5 2 13.5V10.5ZM95.0607 13.0607C95.6464 12.4749 95.6464 11.5251 95.0607 10.9393L85.5147 1.3934C84.9289 0.807611 83.9792 0.807611 83.3934 1.3934C82.8076 1.97919 82.8076 2.92893 83.3934 3.51472L91.8787 12L83.3934 20.4853C82.8076 21.0711 82.8076 22.0208 83.3934 22.6066C83.9792 23.1924 84.9289 23.1924 85.5147 22.6066L95.0607 13.0607ZM2 13.5H94V10.5H2V13.5Z"
                      fill="#E4E7EC"
                  />
                </svg>

              </div>
              <div class="col-md-4 d-flex align-items-center">
                <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect width="48" height="48" rx="24" fill="#20409B"/>
                <path d="M31 16H30V14H28V16H20V14H18V16H17C15.9 16 15 16.9 15 18V32C15 33.1 15.9 34 17 34H31C32.1 34 33 33.1 33 32V18C33 16.9 32.1 16 31 16ZM31 32H17V22H31V32ZM17 20V18H31V20H17ZM19 24H29V26H19V24ZM19 28H26V30H19V28Z" fill="#FCFCFD"/>
                </svg>
                <p class="mt-3 ml-2">{{$t('KYC_steps.lbl_authentication')}}</p>
              </div>
            </div>
            <div class="d-flex align-items-center">
                <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg"
                class="mr-2 cursor-pointer"
                @click="back"
                >
                <path d="M20.0576 11H7.88762L13.4776 5.41L12.0576 4L4.05762 12L12.0576 20L13.4676 18.59L7.88762 13H20.0576V11Z" fill="black"/>
                </svg>
                <p class="fs-18 fw-700 txt-black text-center my-3 cursor-pointer-custom">{{$t('KYC_steps.lbl_appt_confirm')}}</p>
              </div>
            <div class="row">
                <div class="col-md-12 mt-3">
                    <p class="fs-16 fw-500 txt-black">{{$t('KYC_steps.lbl_time')}}</p>
                    <div class="d-flex">
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"
                        class="mr-2"
                        >
                        <path d="M11.99 2C6.47 2 2 6.48 2 12C2 17.52 6.47 22 11.99 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 11.99 2ZM12 20C7.58 20 4 16.42 4 12C4 7.58 7.58 4 12 4C16.42 4 20 7.58 20 12C20 16.42 16.42 20 12 20ZM12.5 7H11V13L16.25 16.15L17 14.92L12.5 12.25V7Z" fill="#20409B"/>
                        </svg>
                        <p class="fs-16 fw-400 txt-black">30 {{$t('KYC_steps.lbl_min')}}</p>
                    </div>
                    <div class="d-flex">
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"
                        class="mr-2"
                        >
                        <path d="M19 4H18V2H16V4H8V2H6V4H5C3.9 4 3 4.9 3 6V20C3 21.1 3.9 22 5 22H19C20.1 22 21 21.1 21 20V6C21 4.9 20.1 4 19 4ZM19 20H5V10H19V20ZM5 8V6H19V8H5ZM7 12H17V14H7V12ZM7 16H14V18H7V16Z" fill="#20409B"/>
                        </svg>
                        <p class="fs-16 fw-400 txt-black">{{time ? time.start_time.format('HH:mm') : '--:--'}}, {{date ? formatDMY(date) : '--/--/----'}}</p>
                    </div>
                </div>
                <!-- <div class="col-md-7 mt-3">
                    <p class="fs-16 fw-600 txt-black">Thông tin liên hệ</p>
                    <div class="mt-3">
                        <label
                        for="txt-name"
                        class="fs-16 fw-600 txt-grey-600"
                        >Họ và tên</label>
                        <input
                        type="text"
                        class="form-control form-input mt-2 fs-16 fw-500 text-grey-600 bg-white"
                        placeholder="Nhập họ và tên"
                        autocomplete="off"
                        />
                    </div>
                    <div class="mt-3">
                        <label
                        for="txt-name"
                        class="fs-16 fw-600 txt-grey-600"
                        >Email</label>
                        <input
                        type="text"
                        class="form-control form-input mt-2 fs-16 fw-500 text-grey-600 bg-white"
                        placeholder="Nhập email"
                        autocomplete="off"
                        />
                    </div>
                    <div class="mt-3">
                        <label
                        for="txt-name"
                        class="fs-16 fw-600 txt-grey-600"
                        >Số điện thoại</label>
                        <input
                        type="text"
                        class="form-control form-input mt-2 fs-16 fw-500 text-grey-600 bg-white"
                        placeholder="Nhập Số điện thoại"
                        autocomplete="off"
                        />
                    </div>
                </div> -->
            </div>
            <div class="mt-5 mb-2">
              <button class="fs-16 fw-600 btn bg-pri bd-pri text-white btn-l w-100 border-radius-18px">
                {{$t('KYC_steps.lbl_book_appt')}}
              </button>
            </div>
          </form>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'VerifyCalendarKyc',
  props: ['time', 'date'],
  data () {
    return {

    }
  },
  computed: {

  },
  mounted () {

  },
  methods: {
    show () {
      window.$('#VerifyCalendarKyc').modal('show')
    },
    close () {
      window.$('#VerifyCalendarKyc').modal('hide')
    },
    back () {
      window.$('#VerifyCalendarKyc').modal('hide')
      window.$('#CalendarKyc').modal('show')
    },
    formatDMY (date) {
      return window.moment(date).format('DD/MM/YYYY')
    },
    formatHH (time) {
      return window.moment(time).format('HH:mm')
    }
  }
}
</script>

<style scoped>
.cursor-pointer-custom:hover {
    color: #20409B;
    cursor: pointer;
    text-decoration: underline;
}
.border-radius-18px {
    border-radius: 18px;
}
.form-input {
  color: #1d2939;
  border: 0.1px solid #98a2b3;
  padding: 13px;
  border-radius: 12px;
}
input:checked + .slider {
  background-color: #20409b;
}
input:checked + .slider:before {
  transform: translateX(20px);
}
input:checked + .active:before {
  transform: translateX(20px);
}
.border-20px {
    border-radius: 20px;
}
</style>