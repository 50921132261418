<template>
        <div ref="publicationWraper" id="publicationTrackWraper">
        </div>
</template>

<script>
export default {
  name: 'PublicationTrack',
  props: ['track'],
  mounted () {
    this.appendTrack(this.track)
  },
  watch: {
    track (t) {
      this.appendTrack(t)
    }
  },
  methods: {
    appendTrack (t) {
      console.log(t)
      const publicationTrackWraper = document.getElementById('publicationTrackWraper')
      if (t) {
        publicationTrackWraper.appendChild(t.attach())
      }
    }
  }
}
</script>

<style scoped>

</style>