<template>
  <div class="login-via-otp container">
    <div class="brand">
      <svg
        width="32"
        height="33"
        viewBox="0 0 32 33"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        class="mr-2"
      >
        <path
          d="M9.91091 30.1993L2.52089 22.8093C-0.840297 19.4481 -0.840297 13.9956 2.52089 10.6311L9.91091 3.2411C13.2721 -0.120082 18.7246 -0.120082 22.0891 3.2411L29.4791 10.6311C32.8403 13.9923 32.8403 19.4448 29.4791 22.8093L22.0891 30.1993C18.7246 33.5605 13.2754 33.5605 9.91091 30.1993Z"
          fill="#20409B"
        />
        <path
          d="M16.1889 10.7327C16.4802 10.7065 16.7584 10.6542 17.0136 10.5723C17.4686 10.448 17.8678 10.2254 18.1951 9.93087C18.2409 9.8916 18.2868 9.84577 18.3326 9.80323L18.349 9.78687C18.9512 9.18467 19.2817 8.33373 19.2326 7.35189C19.1966 6.61223 18.9282 5.9544 18.4995 5.44384C18.3817 5.29983 18.2508 5.16892 18.1068 5.0511C17.5929 4.61908 16.9351 4.35399 16.1954 4.31471C15.2594 4.26889 14.451 4.56344 13.8554 5.11C13.8226 5.13946 13.7932 5.16565 13.7637 5.1951C13.3874 5.55838 13.1092 6.02313 12.9652 6.56641C12.9619 6.58278 12.9553 6.59914 12.9521 6.6155C12.8866 6.84787 12.8408 7.09661 12.8179 7.35516C12.8179 7.38789 12.8113 7.42061 12.8081 7.45007C12.4579 11.1811 10.5433 13.1251 6.84499 13.5081C6.7959 13.5146 6.74681 13.5179 6.69772 13.5244C4.81912 13.6946 3.5918 14.9677 3.60162 16.7383C3.60817 17.6547 3.95508 18.4402 4.55074 19.0064C5.11694 19.602 5.90242 19.9457 6.81553 19.9522C8.58613 19.9653 9.85926 18.738 10.0294 16.8562C10.0327 16.8038 10.0393 16.758 10.0458 16.7089C10.4255 13.0073 12.3728 11.0927 16.1038 10.7425C16.1267 10.7393 16.1594 10.736 16.1889 10.7327Z"
          fill="white"
        />
        <path
          d="M25.3608 19.906C25.6521 19.8799 25.9302 19.8275 26.1855 19.7457C26.6404 19.6213 27.0397 19.3988 27.367 19.1042C27.4128 19.0649 27.4586 19.0191 27.5045 18.9766L27.5208 18.9602C28.123 18.358 28.4536 17.5071 28.4045 16.5252C28.3685 15.7856 28.1001 15.1277 27.6714 14.6172C27.5536 14.4732 27.4226 14.3423 27.2786 14.2244C26.7648 13.7924 26.107 13.5273 25.3673 13.4881C24.4313 13.4422 23.6229 13.7368 23.0273 14.2833C22.9945 14.3128 22.9651 14.339 22.9356 14.3684C22.5592 14.7317 22.281 15.1965 22.137 15.7398C22.1338 15.7561 22.1272 15.7725 22.1239 15.7888C22.0585 16.0212 22.0127 16.2699 21.9898 16.5285C21.9898 16.5612 21.9832 16.594 21.9799 16.6234C21.6298 20.3544 19.7152 22.2985 16.0169 22.6814C15.9678 22.6879 15.9187 22.6912 15.8696 22.6978C13.991 22.868 12.7637 24.1411 12.7735 25.9117C12.78 26.8281 13.127 27.6135 13.7226 28.1797C14.2888 28.7754 15.0743 29.119 15.9874 29.1256C17.758 29.1387 19.0311 27.9114 19.2013 26.0295C19.2046 25.9771 19.2111 25.9313 19.2177 25.8822C19.5973 22.1807 21.5447 20.2661 25.2757 19.9159C25.2986 19.9126 25.328 19.9093 25.3608 19.906Z"
          fill="white"
        />
        <path
          d="M16.0116 19.9489C17.801 19.9489 19.2517 18.4983 19.2517 16.7088C19.2517 14.9194 17.801 13.4688 16.0116 13.4688C14.2221 13.4688 12.7715 14.9194 12.7715 16.7088C12.7715 18.4983 14.2221 19.9489 16.0116 19.9489Z"
          fill="white"
        />
      </svg>
      <p class="mb-0 text-uppercase fs-28 fw-700 txt-pri">HODO</p>
    </div>
    <div class="content">
      <!-- <div class="text-center" v-if="!sended_otp">
        <svg
          width="86"
          height="86"
          viewBox="0 0 86 86"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M12.0457 70.3404H23.4428V42.6685L7.16797 30.4741V65.5095C7.16797 68.1829 9.37233 70.3404 12.0457 70.3404Z"
            fill="#4385F4"
          />
          <path
            d="M62.5625 70.3399H73.9595C76.6798 70.3399 78.8373 68.1355 78.8373 65.4621V30.4268L62.5625 42.6211V70.3399Z"
            fill="#36A852"
          />
          <path
            d="M62.5625 21.4689V42.6214L78.8373 30.427V23.9077C78.8373 17.8575 71.9428 14.4336 67.1119 18.0451L62.5625 21.4689Z"
            fill="#F9BC15"
          />
          <path
            d="M23.4395 42.6682V21.5156L42.9974 36.1958L62.5553 21.5156V42.6682L42.9974 57.3014L23.4395 42.6682Z"
            fill="#EA4535"
          />
          <path
            d="M7.16797 23.9066V30.4259L23.4428 42.6203V21.4677L18.8933 18.0439C14.0625 14.4325 7.16797 17.9032 7.16797 23.9066Z"
            fill="#C52627"
          />
        </svg>
        <p class="fs-56 fw-700 txt-grey-900">Xác thực email</p>
        <p class="fs-20 fw-400 txt-grey-900 mb-5">
          Chúng tôi đã gửi cho bạn một email tới hodo@gmail.com. Nhấp vào liên
          kết ở đó để hoàn thành việc đăng ký của bạn.
        </p>
        <p class="fs-18 fw-500 txt-grey-700">Bạn không nhận được email?</p>
        <p
          class="fs-18 fw-600 txt-pri cursor-pointer"
          @click="onSendOtp()"
          v-if="!time_out"
        >
          Gửi email
        </p>
        <p class="fs-18" v-if="time_out">
          <span class="fw-500 txt-grey-600">Gửi lại OTP sau </span
          ><span class="fw-800 txt-grey-900"
            >00:{{
              time_out && (time_out > 10 ? time_out : "0" + time_out)
            }}</span
          >
        </p>
      </div> -->
      <div v-if="sended_otp" class="text-center">
        <p class="fs-32 fw-700 txt-grey-900">Mã xác thực OTP</p>
        <p class="fs-16 fw-500 txt-grey-700">
          Một mã OTP vừa được gửi đến Email {{ form.email }}
        </p>
        <div class="d-flex justify-content-center mb-3">
          <input
            id="otp1"
            type="number"
            class="input-otp"
            @input="changeOtpInput"
            :readonly="loading"
          />
          <input
            id="otp2"
            type="number"
            class="input-otp"
            @input="changeOtpInput"
            :readonly="loading"
          />
          <input
            id="otp3"
            type="number"
            class="input-otp"
            @input="changeOtpInput"
            :readonly="loading"
          />
          <input
            id="otp4"
            type="number"
            class="input-otp"
            @input="changeOtpInput"
            :readonly="loading"
          />
        </div>
        <p
          class="fs-18 fw-600 txt-pri cursor-pointer"
          @click="onSendOtp()"
          v-if="!time_out"
        >
          Gửi lại email
        </p>
        <p class="fs-18" v-if="time_out">
          <span class="fw-500 txt-grey-600">Gửi lại OTP sau </span
          ><span class="fw-800 txt-grey-900"
            >00:{{
              time_out && (time_out > 10 ? time_out : "0" + time_out)
            }}</span
          >
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import appUtils from '../../utils/appUtils'
export default {
  name: 'OTPLoginCpn',
  props: ['prop_email'],
  data () {
    return {
      form: {
        email: '',
        otp: ''
      },
      sended_otp: false,
      time_out: 0,
      loading: false,
      interval_resend: null,
      otp1: '',
      otp2: '',
      otp3: '',
      otp4: ''
    }
  },
  mounted () {
    if (this.prop_email) {
      this.form.email = this.prop_email
      this.onSendOtp()
    }
  },
  watch: {
    form (f) {
      this.$emit('changeOtp', f.otp)
    }
  },
  methods: {
    async onSendOtp () {
      let self = this
      if (!this.form.email) {
        alert('Bạn chưa nhập email')
        return
      }
      const params = {
        email: this.form.email
      }
      await this.$rf.getRequest('AuthRequest').sendOtpEmail(params).then((r) => {
        self.sended_otp = true
        self.time_out = 30
        self.interval_resend = setInterval(self.countDownTimeout, 1000)
      })
    },
    changeOtpInput (e) {
      let id = e.target.id
      if (e.target.value > 10 || e.target.value < 0) {
        e.target.value = e.data
      }
      if (id === 'otp1') this.otp1 = e.data
      if (id === 'otp2') this.otp2 = e.data
      if (id === 'otp3') this.otp3 = e.data
      if (id === 'otp4') this.otp4 = e.data
      if (!this.otp1) {
        document.getElementById('otp1').focus()
        return
      }
      if (!this.otp2) {
        document.getElementById('otp2').focus()
        return
      }
      if (!this.otp3) {
        document.getElementById('otp3').focus()
        return
      }
      if (!this.otp4) {
        document.getElementById('otp4').focus()
        return
      }
      this.onLogin()
    },
    countDownTimeout () {
      this.time_out -= 1
      if (this.time_out === 0) {
        clearInterval(this.interval_resend)
      }
    },
    async onLogin () {
      const self = this
      if (!this.otp1 || !this.otp2 || !this.otp3 || !this.otp4) {
        alert('Vui lòng nhật OTP')
        return
      }
      const params = {
        email: this.form.email,
        otp: this.otp1 + '' + this.otp2 + '' + this.otp3 + '' + this.otp4
      }
      this.form = params
      try {
        this.loading = true
        let resp = await this.$rf.getRequest('AuthRequest').loginOtp(params)
        if (resp.data.access_token) {
          appUtils.setLocalToken(resp.data.access_token)

          resp = await self.$rf.getRequest('AuthRequest').me()
          if (resp) {
            appUtils.setLocalUser(resp.data)
          }

          self.loading = false

          setTimeout(() => {
            self.$emit('logged')
          }, 300)
        }
      } catch (e) {
        self.$toast.open({
          message: 'Sai OTP',
          type: 'warning'
          // all other options
        })
        console.log(e)
      } finally {
        this.loading = false
      }
    }
  }
}
</script>

<style scoped>
.login-via-otp .brand {
  display: flex;
  align-items: center;
}
.login-via-otp .content {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  max-width: 600px;
  width: 100%; /* Need a specific value to work */
}
.disabled {
  pointer-events: none;
}
.input-otp {
  margin: 4px;
  width: 56px;
  height: 56px;
  border: 1px solid #98a2b3;
  border-radius: 12px;
  text-align: center;
  font-weight: 800;
  font-size: 24px;
}
.input-otp::-webkit-outer-spin-button,
.input-otp::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.input-otp {
  -moz-appearance: textfield;
}
</style>