<template>
    <div class="modal fade" id="modal__link">
        <div class="modal-dialog modal-lg  modal-dialog-centered">
            <div class="modal-content">
                <div class="modal-header bg--blue">
                    <h5 class="modal-title">Khởi tạo cuộc gọi</h5>
                    <button type="button" class="btn-close" data-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <div class="row text-right">
                        <div class="col-sm-12">
                            <input disabled class="form-control" v-model="link_room" />
                            <button class="btn--link" @click="copyLink(link_room)">
                            <img src="../../../public/assets/images/icon/link.svg" width="24" height="24" alt=""> Sao chép link
                        </button>
                        </div>
                    </div>
                    <div class="text-center mt-3">
                        <button class="btn w-50" @click="goToRoom()">
                            Tham gia cuộc gọi
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import appUtils from '../../utils/appUtils'
export default {

  name: 'ModalLinkRoom',
  props: ['link_room', 'appt_id'],
  data () {
    return {

    }
  },
  methods: {
    goToRoom () {
      if (this.appt_id) {
        this.$router.push('/doctor/' + this.appt_id + '/meeting-room')
        let user = appUtils.getLocalUser()
        let clinic = this.$globalClinic
        this.sendAmplitude('Join Room', {
          'doctor id': user.id,
          'doctor name': user.name,
          'workspace id': clinic.id,
          'workspace name': clinic.name,
          'timestamp': window.moment(new Date()).format('HH:mm DD-MM-YYYY'),
          'request id': this.appt_id
        })
      }
    },
    copyLink (link) {
      let url = document.createElement('input')
      document.body.appendChild(url)
      url.value = link
      url.select()
      url.setSelectionRange(0, 99999)
      document.execCommand('copy')
      this.$toast.open({
        message: 'Đã copy vào bộ nhớ tạm',
        type: 'success'
      })
      url.remove()
    }
  }
}
</script>
<style lang="css" scoped>
#modal__link input {
    height: 51px;
    background: #FFFFFF;
    border: 1px solid #7386C7;
    box-sizing: border-box;
    border-radius: 7px;
    color: #20419B;
}

#modal__link .btn {
    height: 51px;
    background: #20419B;
    border-radius: 7px;
    color: white;
}
</style>