<template>
    <div class="row">
        <div class="col-sm-12 col-xxl-12 mt-3">
            <div class="col-12">
                <div class="mh-430 mt-2">
                    <div class="border-itemv3 mt-2" style="box-shadow: 0px 4px 0px 0px #E9E9E9;">
                        <div class="mt-1">
                            <div class="robo-24-500 mt-4 txt-pri mb-2">Hoạt động ({{ message_total || 0}})</div><br>
                            <div class="mt-4 border-itemv3">
                                <div class="request-person-info d-flex flex-shrink-1" v-if="request && request.status != 7 && request.status != 4 && user">
                                    <div class="person-avatar" :set="has_avatar = hasAvatarCheckDoctor(user)">
                                        <span v-if="has_avatar" class="avatar avatar-sm avatar-rounded" :style="'background-image: url('+getImageURL(user.avatar)+')'"></span>
                                        <span v-if="!has_avatar" class="avatar avatar-sm avatar-rounded text-white bg-dark">
                                            <span class="robo-14-500">{{ user.name ? user.name.charAt(0) : '' }}</span>
                                        </span>
                                    </div>
                                    <div class="ml-3 w-100">
                                        <div class="form-group">
                                            <div class="bg-white border p-1">
                                                <textarea class="form-control robo-20-400 w-100 bg-white" :class="file_message && file_message.length ? 'border-bottom bd-muted' : ''" rows="2" placeholder="Nhập nội dung" v-model="txt_content"></textarea>
                                                <div class="p-2 d-flex" v-if="file_message && file_message.length">
                                                    <div class="mr-2 thread-medias-img border bd-muted" v-for="(item, index) in file_message" :key="index" :style="`background-image: url('${getBase64URL(item)}');`" @click="onShowMediaLocal(file_message, index)">
                                                    </div>
                                                </div>
                                            </div>
                                            <span class="robo-12-400 text--red" v-if="!txt_content && is_submit">Vui lòng nhập nội dung!</span>
                                        </div>
                                        <div class="d-flex justify-content-between mt-2 mb-2">
                                            <div class="">
                                                <label>
                                                    <img src="../../../public/assets/images/icon/icon_update_image.svg" class="cursor-pointer">
                                                    <input type="file" name="" class="d-none" accept="image/*" multiple="true" @change="changeImage($event)">
                                                </label>
                                            </div>
                                            <div>
                                                <button :disabled="is_submit" class="btn btn-sm bg-pri bd-pri text-white" style="padding: 6px 39.6px" @click="sendMessage(thread_id)">Gửi</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="border-itemv3 mt-2 mb-3" v-if="threads && threads.length">
                        <div v-for="(message, index) in threads" :key="index" class="mb-3">
                            <div :set="[sender = message.sender, role = message.role, msg_text = message.msg_text]" class="mt-3 border-itemv3 border-bottom">
                                <div class="request-person-info d-flex flex-shrink-1">
                                    <div v-if="sender" class="person-avatar">
                                        <div class="person-avatar" :set="has_avatar = hasAvatar(sender)">
                                            <span v-if="has_avatar" class="avatar avatar-sm avatar-rounded" :style="'background-image: url('+getImageURL(sender.user.avatar)+')'"></span>
                                            <span v-if="!has_avatar" class="avatar avatar-sm avatar-rounded text-white bg-dark">
                                                <span class="robo-14-500">{{ sender && sender.name ? sender.name.charAt(0) : '' }}</span>
                                            </span>
                                        </div>
                                    </div>
                                    <div class="ml-3 w-100 mb-2">
                                        <div class="form-group">
                                            <div class="d-flex justify-content-between align-items-center mb-1">
                                                <p v-if="sender" class="robo-18-500 text-gray mb-0">{{ sender.name }} <span v-if="role" class="robo-16-400 txt-muted">(<span>{{role}}</span>)</span></p>
                                                <p class="robo-16-400 mb-0">{{getTime(message.created_at)}} ngày {{formatDateDMY(message.created_at)}}</p>
                                            </div>
                                            <p class="robo-16-400 txt-grey-900 mb-2">{{msg_text}}</p>
                                            <div class="mt-1" v-if="message.type === 1 || message.type === 2 || message.type === 3" :set="medias = message.attach">
                                                <div align="center" class="border-itemv3 robo-16-400 row" v-if="medias && medias.length">
                                                    <div class="mr-2 col-3 mt-2 thread-medias-img" v-for="(item, index) in medias" :key="index" :style="`background-image: url('${getImageURL(item.path)}');`" @click="onShowMedia(message.attach, index)">
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="mt-1" v-if="(message.type === 4 || message.type === 8 ||  message.type === 14) && message.attach" :set="appointment = message.attach">
                                                <div style="background: #F2F5FA; border-radius: 8px; padding: 8px 12px;">
                                                    <div class="d-flex justify-content-between align-items-center">
                                                        <div v-if="appointment">
                                                            <div class="d-flex align-items-center">
                                                                <img src="../../../public/assets/images/icon/green-walking.svg" style="width: 24px; height: 24px;" v-if="appointment.method == 1">
                                                                <img src="../../../public/assets/images/icon/big-blue-video.svg" style="width: 24px; height: 24px;" v-if="appointment.method == 2">
                                                                <p class="robo-16-500 txt-grey-900 ml-2 mb-0">{{getTime(appointment.start_time)}} ngày {{formatDateDMY(appointment.start_time)}}</p>
                                                            </div>
                                                            <div v-if="appointment.method == 2">
                                                                <div v-if="appointment.status != 6 && appointment.status != 3 && !!appointment.invoice" :set="invoice_arr = getInvoiceStatus(appointment.invoice.status)">
                                                                    <p class="robo-14-500 m-0" :class="invoice_arr.class">
                                                                        {{invoice_arr.text}} <span class="robo-20-500 ml-1">{{appointment.invoice.price && formatCurency(appointment.invoice.price)}}</span>
                                                                    </p>
                                                                </div>
                                                            </div>
                                                            <div v-if="appointment.method == 1 && appointment.clinic">
                                                                <div v-if="appointment.status != 6 && appointment.status != 3 && !!appointment.invoice" :set="clinic = appointment.clinic">
                                                                    <div class="d-flex mt-1">
                                                                        <img src="../../../public/assets/images/icon/icon-location.svg" style="width: 24px; height: 24px;">
                                                                        <span class="ml-2">
                                                                            <p class="robo-16-500 m-0">{{clinic.name}}</p>
                                                                            <p class="robo-14-500 m-0">{{clinic.address}}</p>
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="robo-20-500 text--red" v-if="appointment.status == 6 || appointment.status == 3">
                                                                Lịch hẹn đã kết thúc
                                                            </div>
                                                        </div>
                                                        <div v-if="appointment && appointment.invoice && appointment.method == 2">
                                                            <button v-if="appointment.method == 2 && appointment.is_start" class="btn text-white mr-1 border-0 bg-pri" :disabled="!permitCall (message.attach)" @click="goToCallAppt(message.attach)">
                                                                <img src="../../../public/assets/images/icon/icon-telemedicine.svg" alt="" style="width: 24px; height: 24px;">
                                                                <span class="ml-1" :class="appointment.invoice.status == 3 ? 'text-white' : 'txt-black'">Gọi</span>
                                                            </button>
                                                            <a href="javascript:;" class="btn text-white bg--yellow border-0" @click="goToInvoiceAppt(message.attach)">
                                                                <img src="../../../public/assets/images/icon/icon-invoice.svg" alt="" style="width: 24px; height: 24px;">
                                                                <span class="ml-1 text-white">Hóa đơn</span>
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="mt-1" v-if="message.type === 5">
                                                <div v-if="message.content">
                                                    <div class="d-flex ">
                                                        <div>
                                                            <div class="icon-alter icon-wait"></div>
                                                        </div>
                                                        <div style="background: #FAFAFA; border-radius: 8px; padding: 8px 12px; flex-grow: 1" class="d-flex justify-content-between align-items-center">
                                                            <div class="robo-16-500 mb-2">
                                                                <pre class="border-0 ff-robo" style="background-color: #FAFAFA; padding: 0; margin: 0;">{{message.content.title}}</pre>
                                                            </div>
                                                            <div>
                                                                <div class="robo-12-400 text-black-50 mt-2">{{message.content.created_at ? formatDateDMY(message.content.created_at) : '' }}</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="mt-1" v-if="message.type === 7 || message.type === 6">
                                                <div v-if="message.content">
                                                    <div class="row mt-2" v-if="message.attach && message.attach.ticket && message.attach.ticket" :set="ticket = message.attach.ticket">
                                                        <div class="col-xxl-0 col-2 col-md-1">
                                                            <div class="icon-alter icon-success"></div>
                                                        </div>
                                                        <div class="col-xxl-11 col-10 col-md-11 col-xl-11">
                                                            <div style="background: #FAFAFA; border-radius: 8px; padding: 8px 12px;">
                                                                <div class="robo-16-500 mb-2">
                                                                    {{ticket.title}}
                                                                </div>
                                                                <div v-if="message.attach && message.attach.comments && message.attach.comments.length" class="border-top" v-for="comment in message.attach.comments">
                                                                    <div class="mt-2" v-if="thread.type != 5" align="left">
                                                                        <span class="text-black-50">
                                                                            {{comment.content}}
                                                                        </span>
                                                                        <div class="row" v-if="comment.medias">
                                                                            <div v-for="(media, index) in comment.medias" :key="index" class="col-sm-3 ml-1 mt-1 img-ticket" :style="`background-image: url('${getImageURL(media.path)}');`" style="cursor: pointer;" @click="onShowMedia(comment.medias, index)">
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="d-flex w-100 justify-content-end">
                                                                        <div class="robo-12-400 text-black-50 mt-2">{{comment.created_at ? formatDateDMY(comment.created_at) : '' }}</div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="mt-1" v-if="message.type === 10">
                                                <div v-if="message.content">
                                                    <div class="row mt-2">
                                                        <div class="col-xxl-0 col-2 col-md-1">
                                                            <div class="icon-alter icon-error">
                                                            </div>
                                                        </div>
                                                        <div class="col-xxl-11 col-10 col-md-11 col-xl-11">
                                                            <div style="background: #FAFAFA; border-radius: 8px; padding: 8px 12px;">
                                                                <div class="robo-16-500 mb-2">
                                                                    <pre class="border-0 ff-robo" style="background-color: #FAFAFA; padding: 0; margin: 0;">{{message.content.title}}</pre>
                                                                    <p class="mb-0 robo-16-500">{{(message.attach && message.attach.text) || ''}}</p>
                                                                </div>
                                                                <p class="mb-0 robo-16-500 text-red">{{message.attach && message.attach.text ? ('Lý do: '+ message.attach.text) : ''}}</p>
                                                                <div class="border-top">
                                                                    <div class="d-flex w-100 justify-content-end">
                                                                        <div class="robo-12-400 text-black-50 mt-2">{{formatDateDMY(message.content.created_at)}}</div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="w-100 text-center" v-if="threads.length < message_total">
                            <a href="javascript:;" class="txt-pri robo-14-500 bd-pri border-bottom" @click="nextPageMessage()">Xem thêm</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <CoolLightBox :items="getImagesForCoolight(list_medias)" :index="index_media" :useZoomBar="true" @close="index_media = null"></CoolLightBox>
        <CoolLightBox :items="getImagesForCoolightLocal(list_medias_local)" :index="index_media_local" :useZoomBar="true" @close="index_media_local = null"></CoolLightBox>
    </div>
</template>
<script>
import appUtils from '../../utils/appUtils.js'
export default {
  name: 'Thread',
  props: ['patient', 'request_id', 'is_refresh'],
  watch: {
    is_refresh: function (_new, old) {
      if (_new) {
        this.refreshThread()
      }
    }
  },
  data () {
    return {
      request: null,
      thread: null,
      thread_id: null,
      threads: null,
      user: null,
      status: null,
      txt_content: null,
      is_submit: false,
      index_media: null,
      list_medias: null,
      index_media_local: null,
      list_medias_local: null,
      appointments: null,
      doctors_message: [],
      path_item: null,
      index_img_item: null,
      list_patients: [],
      list_doctors: [],
      list_tickets: [],
      current_page: 1,
      file_message: [],
      message_total: 0
    }
  },
  mounted () {
    let self = this
    self.person_id = self.$route?.params?.id
    self.user = appUtils.getLocalUser()
    self.getRequest()
    // let element = document.querySelector('html')
    // // window.addEventListener('scroll', e => {
    // //   if (Math.ceil(element.scrollTop + element.clientHeight) >= element.scrollHeight) {
    // //     if (self.threads && self.threads.count < self.threads.total) {
    // //       self.getThreadMessage(self.thread_id, self.threads.count + 10)
    // //     }
    // //   }
    // // })
  },
  methods: {
    getDoctorbyDoctorID (doctor_id) {
      let self = this
      try {
        if (self.doctors_message.length === 0) {
          self.getDoctor(doctor_id).then(res => {
            return self.doctors_message[0]
          })
        } else {
          var arr = self.doctors_message.filter(x => x.id === doctor_id)
          if (arr.length === 0) {
            self.getDoctor(doctor_id).then(res => {
              return self.doctors_message[self.doctors_message.length - 1]
            })
          } else {
            return arr[0]
          }
        }
      } catch (e) {
        // statements
        console.log(e)
      }
    },
    async getDoctor (doctor_id) {
      let self = this
      try {
        await self.$rf.getRequest('AuthRequest').getDoctorInfo(doctor_id).then(res => {
          if (res && res.data) {
            self.doctors_message.push(res.data)
            return res.data
          }
        })
      } catch (e) {
        // statements
        console.log(e)
      }
    },
    getApptById (id, appointments) {
      var appt = null
      if (id && appointments && appointments.data) {
        appt = appointments.data.filter(item => item.id === id)
      }
      return appt ? appt[0] : ''
    },
    async getRequest (request_id) {
      let self = this
      try {
        await self.$rf.getRequest('DoctorRequest').getRequestDetail(self.request_id).then(res => {
          if (res && res.data) {
            self.request = res.data
            self.thread = self.request.thread
            self.thread_id = self.request && self.request.thread && self.request.thread.id
            self.getThreadMessage(self.thread_id, 1, true)
          }
        })
      } catch (e) {
        // statements
        console.log(e)
      }
    },
    getImagesForCoolight (files) {
      let results = []
      if (files) {
        files.forEach(file => {
          results.push({
            title: file.name,
            src: appUtils.getImageURL(file.path)
          })
        })
      }
      return results
    },
    getImagesForCoolightLocal (files) {
      let results = []
      if (files) {
        files.forEach(file => {
          results.push({
            title: this.txt_content || 'Ảnh đã chọn',
            src: this.getBase64URL(file),
            type: file.type
          })
        })
      }
      return results
    },
    async getThreadMessage (id_thread, page, renew = false) {
      let self = this
      try {
        var params = {
          limit: 15,
          sort: 'created_at',
          sort_by: 'desc',
          page: page || 1
        }
        let resp = await self.$rf.getRequest('AuthRequest').getThreadsMessage(id_thread, params)

        let new_messages = self.threads && self.threads.length && !renew ? self.threads : []
        if (resp && resp.data && resp.data.count) {
          self.message_total = resp.data.total
          self.current_page = resp.data.current_page
          for (let i = 0; i < resp.data.count; i++) {
            let message = resp.data.data[i]
            let sender = null
            let role = null
            let content = self.getThreadMessageContent(message.content)
            let type = message.type
            let attach = []
            let msg_text = null
            let created_at = message && message.created_at

            if (message.type === 1 || message.type === 2 || message.type === 3) {
              sender = message.person || message.doctor || null
              role = message.doctor ? 'Bác sĩ' : 'Bệnh nhân'
              msg_text = content
              attach = message.medias
            }

            if (message.type === 4 || message.type === 14) {
              attach = await self.getAppointmentDetail(content.id)
              sender = await self.getPatientDetail(content.person_id)
              role = 'Bệnh nhân'
              msg_text = message.type === 14 ? 'Đã từ chối lịch hẹn này' : 'Lịch hẹn'
              if (message.type === 4) {
                let now = window.moment()
                let start_time = window.moment(attach.end_time)
                Object.assign(attach, { is_start: now.diff(start_time) < 86400000 })
              }
            }

            if (message.type === 5) {
              sender = await self.getDoctorDetail(content.doctor_id)
              role = 'Bác sĩ'
              msg_text = 'Đã tạo chỉ dẫn'
              if (message.type === 5) {
                let ticket = await self.getTicketDetail(content.id)
                attach = ticket
              }
            }

            if (message.type === 8) {
              sender = await self.getDoctorDetail(content.doctor_id)
              role = 'Bác sĩ'
              msg_text = 'Lịch hẹn'
              if (message.type === 8) {
                attach = await self.getAppointmentDetail(content.id)
                let now = window.moment()
                let start_time = window.moment(attach.end_time)
                Object.assign(attach, { is_start: now.diff(start_time) < 86400000 })
              };
            }

            if (message.type === 10) {
              sender = await self.getPatientDetail(content.person_id)
              role = 'Bệnh nhân'
              msg_text = 'Đã từ chối thực hiện chỉ dẫn'
              attach = self.getThreadMessageContent(content.cancel_reason)
            }

            if (message.type === 7 || message.type === 6) {
              role = message.type === 7 ? 'Bệnh nhân' : 'Bác sĩ'
              msg_text = 'Đã phản hồi chỉ dẫn'
              let comments = await self.getTicketComments(content.ticket_id) || []
              let doctor = comments.length && comments[0].doctor_id ? await self.getDoctorDetail(comments[0].doctor_id) : []
              let patient = comments.length && self.person_id ? await self.getPatientDetail(self.person_id) : []
              sender = message.type === 7 ? patient : doctor
              let ticket = await await self.getTicketDetail(content.ticket_id) || []
              attach = {
                comments,
                ticket
              }
            }

            if (message.type === 9) {
              if (content.to_id) {
                sender = await self.getDoctorDetail(content.to_id)
                role = 'Bác sĩ'
              }
              msg_text = content.is_booking === 1 ? 'Đã cho phép bênh nhân đặt lịch hẹn' : 'Đã không cho phép bệnh nhân đặt lịch hẹn'
            }
            if (message.type === 11) {
              if (content.person_id) {
                sender = await self.getPatientDetail(content.person_id)
                role = 'Bệnh nhân'
              }
              msg_text = 'Đã dừng yêu cầu'
            }
            if (message.type === 12) {
              if (content.to_id) {
                sender = await self.getDoctorDetail(content.to_id)
                role = 'Bác sĩ'
              }
              msg_text = 'Đã dừng yêu cầu'
            }
            if (message.type === 13) {
              if (content.from_id) {
                sender = await self.getDoctorDetail(content.from_id)
                role = 'Bác sĩ'
              }
              let doctor = null
              if (content.to_id) {
                doctor = await self.getDoctorDetail(content.to_id)
              }
              msg_text = doctor ? 'Đã chuyển yêu cầu cho bác sĩ ' + doctor.name : 'Đã chuyển yêu cầu'
            }
            if (message.type === 14) {
              attach = await self.getAppointmentDetail(content.id)
              sender = await self.getPatientDetail(content.person_id)
              role = 'Bệnh nhân'
              msg_text = 'Đã từ chối lịch hẹn'
            }
            if (message.type === 16) {
              if (message.doctor) {
                sender = message.doctor
                role = 'Bác sĩ'
              }
              msg_text = 'Bác sĩ đã tạo hồ sơ sức khỏe'
            }
            if (message.type === 17) {
              if (message.doctor) {
                sender = message.doctor
                role = 'Bác sĩ'
              }
              msg_text = 'Bác sĩ đã cập nhật hồ sơ sức khỏe'
            }
            let new_message = {
              id: message.id,
              sender,
              role,
              msg_text,
              content,
              type,
              attach,
              created_at
            }
            new_messages.push(new_message)
          }
        }
        self.threads = new_messages
      } catch (e) {
        console.log(e)
      }
    },
    refreshThread () {
      let self = this
      self.getThreadMessage(self.thread_id, 1, true)
      self.txt_content = ''
      self.is_submit = false
    },
    nextPageMessage () {
      let self = this
      if (isNaN(self.current_page) || self.threads.length >= self.message_total) return
      self.getThreadMessage(self.thread_id, self.current_page + 1, false)
    },
    hasAvatar (person) {
      return !!(person && person.user && person.user.avatar)
    },
    hasAvatarCheckDoctor (user) {
      return !!(user && user.avatar)
    },
    getTime (date_time) {
      return window.moment(date_time).format('HH:mm')
    },
    formatDateDMY (date_time) {
      return window.moment(date_time).format('DD/MM/YYYY')
    },
    getImageURL (avatar) {
      return appUtils.getImageURL(avatar)
    },
    async sendMessage (thread_id) {
      let self = this
      try {
        self.is_submit = true
        var params = {
          content: self.txt_content,
          type: 1
        }
        if (self.txt_content) {
          let res = await self.$rf.getRequest('DoctorRequest').postMessageThread(thread_id, params)
          if (self.file_message) {
            var id = res && res.data && res.data.id
            if (id) {
              await self.postMessageUpload(id).finally(() => {
                self.refreshThread()
              })
            } else {
              self.refreshThread()
            }
          } else {
            self.refreshThread()
          }
        }
      } catch (e) {
        console.log(e)
        self.is_submit = false
      }
    },
    async postMessageUpload (id) {
      let self = this
      try {
        var str_type = ''
        var params = new FormData()
        self.file_message.forEach(item => {
          if (item) {
            str_type = str_type + ', ' + item.type
            params.append('images[]', item)
          }
        })
        await self.$rf.getRequest('AuthRequest').postMessageUpload(id, params).then(res => {
          self.file_message = []
          return true
        })
      } catch (e) {
        console.log(e)
      }
    },
    onShowMedia (medias, index) {
      let self = this
      self.list_medias = medias
      self.index_media = index
    },
    onShowMediaLocal (medias, index) {
      let self = this
      self.list_medias_local = medias
      self.index_media_local = index
    },
    getInvoiceStatus (stt) {
      let statuses = {}
      statuses[1] = { text: 'Cần thanh toán', class: 'text--red' }
      statuses[2] = { text: 'Chờ xác nhận', class: 'text--yellow' }
      statuses[3] = { text: 'Đã thanh toán', class: 'text--green' }
      return stt && statuses[stt] ? statuses[stt] : statuses[1]
    },
    getThreadMessageContent (content) {
      try {
        return JSON.parse(content)
      } catch (e) {
        return content
      }
    },
    async getAppointmentDetail (id) {
      let params = {}
      try {
        let resp = await this.$rf.getRequest('DoctorRequest').getAppts(id, params)
        return resp.data
      } catch (error) {
        console.log(error)
      } finally {}
    },
    async getPatientDetail (id) {
      let params = {}
      let patient = this.list_patients.find(p => p.id === id)
      if (patient) {
        return patient
      } else {
        try {
          let resp = await this.$rf.getRequest('DoctorRequest').getPatientInfor(id, params)
          this.list_patients.push(resp.data)
          return resp.data
        } catch (error) {
          console.log(error)
        } finally {}
      }
    },
    async getDoctorDetail (id) {
      let doctor = this.list_doctors.find(p => p.id === id)
      if (doctor) {
        return doctor
      } else {
        try {
          let resp = await this.$rf.getRequest('AuthRequest').getDoctorInfo(id)
          this.list_doctors.push(resp.data)
          return resp.data
        } catch (error) {
          console.log(error)
        } finally {}
      }
    },
    async getTicketDetail (id) {
      let currTicket = this.list_tickets.find(tk => tk.id === id)
      if (currTicket) {
        return currTicket
      } else {
        try {
          let resp = await this.$rf.getRequest('DoctorRequest').getDoctorTicketsByID(id)
          this.list_tickets.push(resp.data)
          return resp.data
        } catch (error) {
          console.log(error)
        } finally {}
      }
    },
    async getTicketComments (id) {
      let params = {
        ticket_id: id
      }
      try {
        let resp = await this.$rf.getRequest('DoctorRequest').getDoctorTicketComments(params)
        return (resp.data && resp.data.data) || []
      } catch (error) {
        console.log(error)
      } finally {}
    },
    goToInvoiceAppt (appt) {
      if (appt && appt.invoice) {
        let invoice = appt.invoice
        this.$router.push({ path: '/doctor/invoice/' + invoice.id + '?token=' + invoice.token })
      }
    },
    async goToCallAppt (appt) {
      let self = this
      if (appt && appt.room_call) {
        try {
          await self.$rf.getRequest('DoctorRequest').getRoomInfo(appt.room_call.id).then(res => {
            if (res) {
              self.$router.push('/doctor/' + appt.id + '/meeting-room')
            }
          })
        } catch (e) {
          console.log(e)
        }
      }
    },
    permitCall (appt) {
      let self = this
      // if (appt && (appt.status === 2 || appt.status === 5) && !self.isExpire(appt.start_time)) {
      if (appt && (appt.status === 2 || appt.status === 5)) {
        if (appt.room_call) {
          if (self.isPaid(appt)) {
            return true
          } else {
            return false
          }
        } else {
          return false
        }
      }
    },
    isPaid (appt) {
      if (appt) {
        if (appt.invoice) {
          return appt.invoice.status === 3
        } else {
          return false
        }
      }
    },
    changeImage (event) {
      let self = this
      self.file_message = event.target.files
    },
    getBase64URL (file) {
      return URL.createObjectURL(file)
    },
    formatCurency (str) {
      return str ? str.toLocaleString('it-IT', {style: 'currency', currency: 'VND'}) : '0'
    }
  }
}
</script>
<style lang="css" scoped>
.person-avatar .avatar {
    width: 36px;
    height: 36px
}

.thread-medias-img {
    width: 126px;
    height: 126px;
    border-radius: 12px;
    background-size: cover;
    cursor: pointer;
}

.bg--black-gray {
    color: #6C6C6C !important;
}
a[disabled] {
    cursor: not-allowed;
}
.btn:hover {
  background-color: #536CBC;
}
</style>