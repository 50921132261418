<template>
  <div class="progress-box shadow">
    <div class="pb-header p-3">
      <p class="robo-16-400 pb-title text-white mb-0">{{title || 'Đang tải'}}</p>
    </div>
    <div class="progress">
      <div
        class="progress-bar"
        role="progressbar"
        :style="'width:' + (value || 0) +'%'"
        :aria-valuenow="value"
        aria-valuemin="0"
        aria-valuemax="100"
      ></div>
    </div>
    <div class="d-flex bg-white p-3 justify-content-between pb-body">
      <div class="d-flex">
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          class="mr-2"
        >
          <path
            d="M24 7.61068C24 6.5175 23.1138 5.6313 22.0206 5.6313H11.0311C10.5219 5.6313 10.0323 5.43505 9.66403 5.08335L7.20311 2.73301C6.83486 2.38131 6.34522 2.18506 5.836 2.18506H1.97938C0.886202 2.18506 0 3.07126 0 4.16444V19.5046C0 20.5978 0.8862 21.484 1.97938 21.484H22.0206C23.1138 21.484 24 20.5978 24 19.5046V7.61068Z"
            fill="#20419B"
          />
          <rect
            x="3.2168"
            y="3.42236"
            width="19.0515"
            height="14.8454"
            rx="1.73196"
            fill="#F1BD19"
          />
          <path
            d="M0 7.61068C0 6.5175 0.886199 5.6313 1.97938 5.6313H12.9689C13.4781 5.6313 13.9677 5.43505 14.336 5.08335L16.7969 2.73301C17.1651 2.38131 17.6548 2.18506 18.164 2.18506H22.0206C23.1138 2.18506 24 3.07126 24 4.16444V19.5046C24 20.5978 23.1138 21.484 22.0206 21.484H1.97938C0.886199 21.484 0 20.5978 0 19.5046V7.61068Z"
            fill="#20419B"
          />
          <rect x="18.3086" y="2.18506" width="2.2268" height="2.2268" fill="white" />
          <rect x="18.3086" y="6.63867" width="2.2268" height="2.2268" fill="white" />
          <rect x="18.3086" y="11.0923" width="2.2268" height="2.2268" fill="white" />
          <rect x="20.5361" y="4.41211" width="2.2268" height="2.2268" fill="white" />
          <rect x="20.5361" y="8.86572" width="2.2268" height="2.2268" fill="white" />
          <rect x="20.5361" y="13.3193" width="2.2268" height="2.2268" fill="white" />
          <rect
            x="19.2988"
            y="15.5459"
            width="2.2268"
            height="3.71134"
            rx="0.247423"
            fill="#F1BD19"
          />
          <rect
            x="19.7939"
            y="18.2676"
            width="1.23711"
            height="0.494845"
            rx="0.247423"
            fill="#20419B"
          />
        </svg>
        <p class="robo-16-500 mb-0">{{name}}</p>
      </div>
      <p class="robo-16-400 mb-0">
        <span v-if="value && value === 100">Tải lên hoàn tất</span>
        <span v-if="!value || value < 100">Đang tải lên {{value || 0}}%</span>
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ProgressBox',
  props: ['title', 'value', 'name']
}
</script>

<style scoped>
.progress-box {
  width: 100%;
  max-width: 400px;
  position: absolute;
  top: 66px;
  right: 16px;
  border-radius: 10px;
  z-index: 1052;
}
.pb-header {
  background-color: #323232;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}
.progress {
  border-radius: 0;
  height: 5px;
}
.progress-bar {
  background-color: #20419b;
}
.pb-body {
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}
</style>
