<template>
    <div>
        <div class="modal" tabindex="-1" role="dialog" id="modal__comments-history">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-header bg-white border-0">
                        <h5 class="modal-title" style="color: #20419B;">Lịch sử chỉnh sửa</h5>
                        <small class="mr-3" data-dismiss="modal" aria-label="Close" style="cursor: pointer; color: #4F4F4F;">&times;</small>
                    </div>
                    <div class="modal-body">
                    	<div class="py-3 border-bottom" :set="created = comment && comment.doctor">
                    			<div class="d-flex w-100">
		                    		<div class="left-comment d-flex justify-content-center align-items-start">
		                    			<div class="person-avatar">
		                    				<span v-if="hasAvatar(created)" class="avatar avatar-sender avatar-rounded" :style="'background-image: url('+getImageURL(created.user.avatar)+')'"></span>
		                    				<span v-if="!hasAvatar(created)" class="avatar avatar-sender avatar-rounded text-white bg-dark">
		                    					<span class="robo-14-500">{{ created && created.user && created.user.name ? created.user.name.charAt(0) : '' }}</span>
		                    				</span>
		                    			</div>
		                    		</div>
		                    		<div class="right-comment pl-3">
		                    			<div class="d-flex justify-content-between align-items-center">
		                    				<p class="mb-0 robo-20-500 txt-black" v-if="created"> {{created && created.user && created.user.name}}</p>
		                    				<p class="mb-0 ml-auto">
		                    					<span class="now">Hiện tại</span>
		                    				</p>
		                    			</div>
		                    			<div class="robo-16-400 txt-grey-900" v-if="comments && comments.updated_at">{{formatHMNDMY(comments.updated_at)}}</div>
		                    			<p class="mt-2 txt-grey-900"> <pre class="m-0 p-0 bg-transparent border-0 robo-18-400">{{comments && comments.content}}</pre></p>
		                    		</div>
		                    	</div>
                    		</div>
                    	<div v-if="comments && comments.update_histories">
                    		<div class="py-3" v-for="(cmt, index) in comments.update_histories" :set="created = comment && comment.doctor" :class="index == (comments.update_histories.length - 1) ? '' : 'border-bottom'">
                    			<div class="d-flex w-100">
		                    		<div class="left-comment d-flex justify-content-center align-items-start">
		                    			<div class="person-avatar">
		                    				<span v-if="hasAvatar(created)" class="avatar avatar-sender avatar-rounded" :style="'background-image: url('+getImageURL(created.user.avatar)+')'"></span>
		                    				<span v-if="!hasAvatar(created)" class="avatar avatar-sender avatar-rounded text-white bg-dark">
		                    					<span class="robo-14-500">{{ created && created.user && created.user.name ? created.user.name.charAt(0) : '' }}</span>
		                    				</span>
		                    			</div>
		                    		</div>
		                    		<div class="right-comment pl-3">
		                    			<div class="d-flex justify-content-between align-items-center">
		                    				<p class="mb-0 robo-20-500 txt-black" v-if="created"> {{created && created.user && created.user.name}}</p>
		                    			</div>
		                    			<div class="robo-16-400 txt-grey-900" v-if="cmt.created_at">{{formatHMNDMY(cmt.created_at)}}</div>
		                    			<p class="mt-2 robo-18-400 txt-grey-900 text-justify"> <pre class="m-0 p-0 bg-transparent border-0 robo-18-400">{{cmt.content || ''}}</pre></p>
		                    		</div>
		                    	</div>
                    		</div>
                    	</div>
                    </div>
                    <div class="modal-footer">
                    	<div align="right">
                    		<button type="button" class="btn bg-pri bd-pri text-white" data-dismiss="modal">Đóng</button>
                    	</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import appUtils from '../../utils/appUtils.js'
export default {
  name: 'ModalCommentsHistory',
  props: ['cmt'],
  data () {
    return {
      id: '',
      current_page: 1,
      comments: null,
      comment: null
    }
  },
  watch: {
    cmt: function (_new, old) {
      let self = this
      self.id = _new.id
      self.comment = _new
      if (self.id) {
        self.getTopicComment(self.id)
      }
    }
  },
  mounted () {
  },
  methods: {
    async getTopicComment (id) {
      let self = this
      self.loading = true
      self.current_page = parseInt(self.$route.query.page) || 1
      let params = {
        page: self.current_page,
        limit: 10,
        sort: 'created_at',
        sort_by: 'desc'
      }
      await self.$rf.getRequest('AuthRequest').showCommentHistory(id, params).then((resp) => {
        self.comments = resp.data
        if (self.comments && self.comments.update_histories) {
          self.comments.update_histories.sort((per, next) => {
            return next.id - per.id
          })
        }
      }).finally(() => {
        self.loading = false
      })
    },
    getImageURL (path) {
      return appUtils.getImageURL(path)
    },
    hasAvatar (created) {
      return created && created.user && created.user.avatar
    },
    formatHMNDMY (date_time) {
      let str = window.moment(date_time).format('HH:mm DD/MM/YY')
      return str.replace(' ', ' ngày ')
    }
  }
}
</script>
<style scoped>
.left-comment {
  width: 48px;
  height: 48px;
}
.right-comment {
  width: calc(100% - 48px);
}
.now {
  color: #DC0026;
  background-color: #FFE8EC;
  padding: 4px 9px;
  font-weight: bold;
  border-radius: 6px;
  margin: 2px 2px;
  font-size: 14px;
}
</style>