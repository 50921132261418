<template>
  <div>
    <div class="table-responsive">
      <table
        v-if="list_tests && list_tests.count"
        class="table table-vcenter table-mobile-md card-table table-scroll mb-4"
      >
        <thead>
          <tr>
            <th>
              <span class="ml-sm-2 robo-16-500" style="color: #3153B1;">Chỉ số</span>
            </th>
            <th class="text-sm-center">
              <span class="ml-sm-2 robo-16-500" style="color: #3153B1;">Kết quả</span>
            </th>
            <th class="text-sm-center">
              <span class="ml-sm-2 robo-16-500" style="color: #3153B1;">Vượt chỉ số</span>
            </th>
            <th class="text-sm-center">
              <span class="ml-sm-2 robo-16-500" style="color: #3153B1;">Đơn vị</span>
            </th>
            <th class="text-sm-center">
              <span class="ml-sm-2 robo-16-500" style="color: #3153B1;">Bình thường</span>
            </th>
          </tr>
        </thead>
        <tbody v-if="list_tests && list_tests.count">
          <tr class="mt-2 mb-2 bg-blue-gray" v-for="(test, index) in list_tests.data" :key="index">
            <td data-label="Chỉ số">
              <span class="robo-16-400 txt-black ml-sm-2">{{test.test_name || ''}}</span>
            </td>
            <td data-label="Kết quả" class="text-sm-center">
              <span
                class="robo-16-400 txt-black"
              >{{ test.test_value_text ? test.test_value_text : roundToFive(test.test_value) || ''}}</span>
            </td>
            <td
              data-label="Vượt chỉ số"
              class="text-sm-center"
              :set="surpass = getSurpassTheIndex(test.lower_threshold_text || '', test.upper_threshold_text || Infinity, test.test_value_text || '')"
            >
              <div class="robo-16-500" :class="surpass && surpass.class">{{surpass && surpass.text}}</div>
            </td>
            <td data-label="Đơn vị" class="robo-16-500 text-sm-center">{{test.test_unit || ''}}</td>
            <td data-label="Bình thường" class="text-sm-center">
              <span
                v-if="(!test.upper_threshold_text && test.lower_threshold_text) || (!test.upper_threshold && test.lower_threshold_text) || (!test.upper_threshold && test.lower_threshold) || (!test.upper_threshold_text && test.lower_threshold)"
              >&#8805;</span>
              <span
                v-else-if="(test.upper_threshold_text && !test.lower_threshold_text) || (test.upper_threshold && !test.lower_threshold_text) || (test.upper_threshold && !test.lower_threshold) || (test.upper_threshold_text && !test.lower_threshold)"
              >&#8804;</span>
              {{test.lower_threshold_text ? test.lower_threshold_text : roundToFive(test.lower_threshold)}}
              <span
                v-if="(test.lower_threshold_text && test.upper_threshold_text) || (test.lower_threshold_text && test.upper_threshold) || (test.lower_threshold && test.upper_threshold_text) || (test.lower_threshold && test.upper_threshold) "
              >-</span>
              {{test.upper_threshold_text ? test.upper_threshold_text : roundToFive(test.upper_threshold)}}
            </td>
          </tr>
        </tbody>
        <tbody v-if="(!list_tests || !list_tests.count) && (!documents || !documents.length)">
          <tr>
            <td colspan="5">
              <div class="text-center pt-3 robo-16-500 txt-pri">Chưa có kết quả</div>
            </td>
          </tr>
        </tbody>
      </table>
      <Pagination
        v-if="list_tests && list_tests.count && list_tests.count < list_tests.total"
        :items="list_tests"
        :current_page="list_tests.current_page"
        @onRefresh="getTestsByDiseases"
      ></Pagination>
      <tbody
        v-if="documents && documents.length"
        style="height: 200px; overflow-x: auto; overflow-y: hidden;"
      >
        <tr>
          <td colspan="5">
            <p class="robo-18-500 txt-pri my-1">Tài liệu đính kèm</p>
            <div class="w-100 d-flex mb-2 mt-3">
              <div v-for="(doc,idx) in documents" :key="'doc'+doc.id">
                <div v-if="isImage(doc.name)">
                  <img
                    :src="getLinkDownload(doc.link)"
                    alt
                    width="200"
                    height="100"
                    @click="index=idx"
                    class="cursor-pointer mr-2 bg-dark img-fit-cover"
                  />
                  <div class="text-center mt-1 file-name">
                    <p class="txt-pri-corl mb-0">{{doc.content}}</p>
                    <p class="txt-pri-corl">{{formatDateDMY(doc.created_at)}}</p>
                  </div>
                </div>
                <a
                  v-if="!isImage(doc.name)"
                  target="_blank"
                  :href="getLinkDownload(doc.link)"
                  :download="doc.name"
                  class="mr-2"
                  style="width: 200px"
                >
                  <div class="cursor-pointer d-flex justify-content-center align-items-center">
                    <img
                      src="../../../public/assets/images/icon/dowload-file.svg"
                      alt
                      width="100"
                      height="100"
                      class="cursor-pointer"
                    />
                  </div>
                  <div class="text-center mt-1 file-name">
                    <p class="txt-pri-corl mb-0">{{doc.content}}</p>
                    <p class="txt-pri-corl">{{formatDateDMY(doc.created_at)}}</p>
                  </div>
                </a>
              </div>
            </div>
          </td>
        </tr>
      </tbody>
    </div>
  </div>
</template>

<script>
import Pagination from '../Pagination.vue'

import appUtils from '../../utils/appUtils'
export default {
  name: 'TestsTable',
  props: ['list_tests', 'documents'],
  components: { Pagination },
  data () {
    return {}
  },
  methods: {
    getTestsByDiseases () {
      this.$emit('getTestsByDiseases')
    },
    getSurpassTheIndex (lower, upper, value) {
      if (!value) return ''
      var params = [
        {
          text: 'L',
          class: 'text-primary'
        },
        {
          text: 'H',
          class: 'text-red'
        }
      ]
      if (Math.round(value * 100) < Math.round(lower * 100)) {
        return params[0]
      } else if (Math.round(value * 100) > Math.round(upper * 100)) {
        return params[1]
      }
    },
    getLinkDownload (link) {
      return appUtils.getDocumentURL(link)
    },
    formatDateDMY (date) {
      return window.moment(date).format('DD/MM/YYYY')
    },
    roundToFive (num) {
      if (!num) return ''
      return +(Math.round(num + 'e+5') + 'e-5')
    },
    isImage (file_name) {
      return file_name.toLowerCase().match(/\.(jpeg|jpg|gif|png)$/) != null
    }
  }
}
</script>

<style scoped>
</style>